import React from 'react';
import styles from './dashboard.module.scss';
import { PendingWithdraws } from '../withdraws/pending-withdraws';
import { HeaderApp } from '../utils/header';
import { PartnerBalances } from '../partners/partner-balances';

export const Dashboard = () => {
    return (
        <div className={styles.dashboard}>
            <HeaderApp title="Dashboard" />
            <PendingWithdraws />
            <PartnerBalances />
        </div>
    );
};
