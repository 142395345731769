import React, { FunctionComponent, useRef } from 'react';
import styles from './menu.module.scss';
import Logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { getLoggedUser } from '../../../store/authSlice';
import useOnClickOutside from '../../../hooks/useOnclickOutside';
import { getMenuIsOpen, toggleMenuIsOpen } from '../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getRoutesWithMenu } from '../../../utils/routes';

export const Menu: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const loggedUser = useAppSelector(getLoggedUser)!;
    const isOpened = useAppSelector(getMenuIsOpen);

    const ref = useRef(null);

    const toggleOpened = () => dispatch(toggleMenuIsOpen());

    useOnClickOutside(ref, () => {
        if (!isOpened)
            return;

        toggleOpened();
    });

    return (
        <>
            <div className={`${styles.menu} ${isOpened ? styles.isOpened : ''}`} ref={ref}>
                <header>
                    <img src={Logo} alt="Logo GrootBit" />
                </header>
                <div className={styles.routes}>
                    {getRoutesWithMenu(loggedUser).map((menu, key) => {
                        const isActive = window.location.pathname.includes(menu.path);

                        return (
                            <Link
                                key={key}
                                className={isActive ? styles.active : ''}
                                to={menu.path}
                                onClick={toggleOpened}>

                                <img src={menu.icon} alt="" />
                                <span>{menu.label}</span>

                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
