import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../types/Client';
import { AppThunk, RootState } from './index';
import TronGatewayService from '../services/TronGatewayService';
import { EthereumStandaloneTransferRequest } from '../types/EthereumTransfer';
import { showAlert } from './notificationSlice';
import { Amount, CoinEnum } from '../types/Amount';
import { TronAddress, TronAddressWithClient, TronBalance } from '../types/TronAddress';

interface TronGatewayState {
    addresses: TronAddress[];
    selectedAddress: TronAddress | null;
    balances: TronBalance[];
    isTransferLoading: boolean;
    transactionId: string | null;
}

const initialState: TronGatewayState = {
    addresses: [],
    balances: [],
    selectedAddress: null,
    isTransferLoading: false,
    transactionId: null,
};

const tronWalletsSlice = createSlice({
    name: 'tronGateway',
    initialState,
    reducers: {
        addBalance: (state, action: PayloadAction<TronBalance>) => {
            state.balances = [...state.balances, action.payload];
        },
        updateWallets: (state, action: PayloadAction<TronAddress[]>) => {
            state.addresses = action.payload;
        },
        clearTronState: (state) => {
            state.addresses = [];
            state.balances = [];
            state.selectedAddress = null;
        },
        openTransferPopUp: (state, action: PayloadAction<TronAddress>) => {
            state.selectedAddress = action.payload;
        },
        closeTransferPopUp: (state) => {
            state.selectedAddress = null;
            state.transactionId = null;
        },
        setTransferLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransferLoading = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string>) => {
            state.transactionId = action.payload;
        },
    },
});

export const {
    addBalance,
    clearTronState,
    updateWallets,
    openTransferPopUp,
    closeTransferPopUp,
    setTransferLoading,
    setTransactionId,
} = tronWalletsSlice.actions;

export const fetchWallets = (): AppThunk => dispatch =>
    TronGatewayService.listWallets()
        .then(async lst => {
            dispatch(updateWallets(lst));
        });

export const fetchBalance = (wallet: TronAddress): AppThunk => dispatch =>
    TronGatewayService.getBalance(wallet.address)
        .then(x => {
            dispatch(addBalance(x));
        });

export const makeTransfer = (req: EthereumStandaloneTransferRequest): AppThunk => dispatch => {
    dispatch(setTransferLoading(true));
    TronGatewayService.transfer(req)
        .then(x => {
            dispatch(setTransactionId(x.txId));
            dispatch(showAlert('Transfer created'));
        })
        .finally(() => {
            dispatch(setTransferLoading(false));
        });
};

export const aggregateOnTron = (
    minimum: number,
    coin: CoinEnum,
    address: string
): AppThunk<Promise<void>> => dispatch =>
    TronGatewayService.aggregate(minimum, coin, address)
        .then(() => {
            dispatch(showAlert('Aggregation made'));
        });


export const getSelectedAddress = (state: RootState): TronAddress | null => state.tronWallets.selectedAddress;
export const getTransferIsLoading = (state: RootState): boolean => state.tronWallets.isTransferLoading;
export const getTransactionId = (state: RootState): string | null => state.tronWallets.transactionId;
export const getTronBalance = (wallet: TronAddress) => (state: RootState): Amount[] =>
    state.tronWallets.balances.find(x => x.clientId === wallet.clientId)?.balance || [];
export const getSelectedAddressBalance = (state: RootState): Amount[] => {
    const address = getSelectedAddress(state);
    if (!address)
        return [];
    return getTronBalance(address)(state);
};

export const getAddressesWithClients = (state: RootState): TronAddressWithClient[] => {
    const { addresses } = state.tronWallets;
    if (!addresses)
        return [];

    const clients = state.client.lstClients;

    const findClient = (id: string | undefined): Client | undefined => clients.find(x => x.id === id);

    return addresses.map(address => ({
        ...address,
        client: findClient(address.clientId),
    }));
};

export default tronWalletsSlice.reducer;
