import React, { FunctionComponent } from 'react';
import { Withdraw, WithdrawHistoryEntry } from '../../../types/Withdraw';
import { WithData } from '../../../types';
import { labelWithdrawStatus } from '../../../utils/formatters';

interface Props {
    withdraw: Withdraw;
}

const HistoryItem: FunctionComponent<WithData<WithdrawHistoryEntry>> = ({ data }) => (
    <tr>
        <td data-title="Date">{data.createdAt.toLocaleString()}</td>
        <td data-title="Status">{labelWithdrawStatus(data)}</td>
    </tr>
);

export const WithdrawHistory: FunctionComponent<Props> = ({ withdraw }) => {
    return <table>
        <thead>
        <tr>
            <th>Date</th>
            <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {withdraw.history.map((x, key) => (
            <HistoryItem data={x} key={key} />
        ))}
        </tbody>
    </table>;
};
