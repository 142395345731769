import { CoinEnum, NetworkEnum } from '../types/Amount';


export interface Coin {
    id: CoinEnum;
    label: string;
    symbol: string;
    placeholder: string;
    maxDecimalPlaces?: number;
    networks: NetworkEnum[];
}

export const createMap = <T, U extends { id: T }>(list: U[]): Map<T, U> => {
    const result = new Map<T, U>();
    list.forEach(x => result.set(x.id, x));

    return result;
};

export const coinOptions: Coin[] = [
    {
        id: CoinEnum.usdt,
        label: 'Tether',
        symbol: 'USDT',
        placeholder: '89721',
        maxDecimalPlaces: 4,
        networks: [NetworkEnum.tron, NetworkEnum.ethereum]
    },
    {
        id: CoinEnum.btc,
        label: 'Bitcoin',
        symbol: 'BTC',
        placeholder: '0.000003',
        maxDecimalPlaces: 8,
        networks: [NetworkEnum.bitcoin]
    },
    {
        id: CoinEnum.usdc,
        label: 'USDC Coin',
        symbol: 'USDC',
        placeholder: '89785',
        maxDecimalPlaces: 4,
        networks: [NetworkEnum.ethereum]
    },
    {
        id: CoinEnum.eth,
        label: 'Ethereum',
        symbol: 'ETH',
        placeholder: '0.03851',
        maxDecimalPlaces: 5,
        networks: [NetworkEnum.ethereum]
    },
    {
        id: CoinEnum.trx,
        label: 'Tron',
        symbol: 'TRX',
        placeholder: '125.800044',
        maxDecimalPlaces: 6,
        networks: [NetworkEnum.tron]
    },
];
export const coins = createMap(coinOptions);
export const getCoinInfo = (type: CoinEnum): Coin => coins.get(type)!;
