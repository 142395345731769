import React from 'react';
import styles from './snack-bar.module.scss';
import { getSnack } from '../../../store/notificationSlice';
import { useAppSelector } from '../../../hooks';

export const SnackBar = () => {
    const snack = useAppSelector(getSnack);

    const showStyle = !!snack ? styles.show : '';
    return <div className={`${styles.snackBar} ${showStyle}`}>{snack}</div>;
};
