import React from 'react';
import { RoleEnum, User } from '../types/User';
import { Dashboard } from '../components/dashboard';
import ChartIcon from '../assets/images/icons/leaderboard.svg';
import SlimWalletIcon from '../assets/images/icons/slim-wallet.svg';
import AccountBalanceIcon from '../assets/images/icons/account-balance-wallet.svg';
import ManageAccountsIcon from '../assets/images/icons/manage-accounts.svg';
import { EthereumWallets } from '../components/ethereum-wallets';
import { TronWallets } from '../components/tron-wallets';
import ClientsIcon from '../assets/images/icons/people.svg';
import HandshakeIcon from '../assets/images/icons/handshake.svg';
import { Clients } from '../components/clients';
import { Client } from '../components/clients/client';
import { Partners } from '../components/partners';
import { Operators } from '../components/operators';
import { UpdateOperator } from '../components/operators/update-operator';
import { AddOperator } from '../components/operators/add-operator';
import { MySelf } from '../components/my-self';
import { EditPartner } from '../components/partners/editPartner';
import { AddPartner } from '../components/partners/addPartner';


export interface IRoute {
    path: string;
    Component: React.FunctionComponent;
    authorized?: RoleEnum[];
}

export interface MenuItem extends IRoute {
    icon: any;
    label: string;
}

const allRoutesWithMenu: MenuItem[] = [
    {
        icon: ChartIcon,
        label: 'Dashboard',
        path: '/dashboard',
        Component: Dashboard,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: ClientsIcon,
        label: 'Clients',
        path: '/clients',
        Component: Clients,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        icon: HandshakeIcon,
        label: 'Partners',
        path: '/partners',
        Component: Partners,
        authorized: [RoleEnum.admin],
    },
    {
        icon: ManageAccountsIcon,
        label: 'Operators',
        path: '/operators',
        Component: Operators,
        authorized: [RoleEnum.admin],
    },
    {
        icon: SlimWalletIcon,
        label: 'Ethereum Wallets',
        path: '/ethereum-wallets',
        Component: EthereumWallets,
        authorized: [RoleEnum.admin]
    },
    {
        icon: AccountBalanceIcon,
        label: 'Tron Wallets',
        path: '/tron-wallets',
        Component: TronWallets,
        authorized: [RoleEnum.admin]
    },
];

const allRoutesWithoutMenu: IRoute[] = [
    {
        path: '/clients/:id/*',
        Component: Client,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
    {
        path: '/partners/add',
        Component: AddPartner,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/partners/edit',
        Component: EditPartner,
        authorized: [RoleEnum.admin]
    },
    {
        path: '/partners/:id',
        Component: EditPartner,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/operators/add',
        Component: AddOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/operators/:id',
        Component: UpdateOperator,
        authorized: [RoleEnum.admin],
    },
    {
        path: '/my-self',
        Component: MySelf,
        authorized: [RoleEnum.admin, RoleEnum.operator],
    },
];

export const getRoutesWithMenu = (user: User): MenuItem[] =>
    allRoutesWithMenu.filter(route =>
        !!route.authorized ? route.authorized.includes(user.role) : true);

const getRoutesWithoutMenu = (user: User): IRoute[] =>
    allRoutesWithoutMenu.filter(route => !!route.authorized ? route.authorized.includes(user.role) : true);

export const getRoutes = (user: User): IRoute[] =>
    [...getRoutesWithMenu(user), ...getRoutesWithoutMenu(user)];
