import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { BasePartner, Fee } from '../../../types/Partner';
import { Field } from '../../utils/field';
import { Button } from '../../utils/button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { clearRecentlyCreatedSecret, getPartnerIsLoading, getRecentlyCreatedSecret } from '../../../store/partnerSlice';
import { FormFieldWithHint } from '../../utils/form-field-with-hint';
import { CopyButton } from '../../utils/copy-button';
import styles from './form-partner.module.scss';
import AddIcon from '../../../assets/images/icons/add.svg';
import { IconButton } from '../../utils/icon-button';

interface Props {
    title: string;
    id?: string;
    value?: BasePartner;
    labelAction: string;
    onAction: (data: BasePartner) => Promise<void>;
    children?: React.ReactNode;
}

export interface OptionalFee {
    fixed?: number;
    percentage?: number;
}

const defaultFee: OptionalFee = {
    fixed: 0,
    percentage: 0
};

export const FormPartner: FunctionComponent<Props> = props => {
    const isLoading = useAppSelector(getPartnerIsLoading);
    const dispatch = useAppDispatch();

    const secret = useAppSelector(getRecentlyCreatedSecret);

    const [name, setName] = useState('');
    const [webhookUrl, setWebhookUrl] = useState<string>();

    const [withdrawBtc, setWithdrawBtc] = useState<OptionalFee>(defaultFee);
    const [withdrawUsdc, setWithdrawUsdc] = useState<OptionalFee>(defaultFee);
    const [withdrawEth, setWithdrawEth] = useState<OptionalFee>(defaultFee);
    const [withdrawTrx, setWithdrawTrx] = useState<OptionalFee>(defaultFee);
    const [withdrawUsdtEth, setWithdrawUsdtEth] = useState<OptionalFee>(defaultFee);
    const [withdrawUsdtTrx, setWithdrawUsdtTrx] = useState<OptionalFee>(defaultFee);

    const isInvalid = !name ||
        withdrawBtc.fixed === undefined || withdrawBtc.percentage === undefined ||
        withdrawUsdc.fixed === undefined || withdrawUsdc.percentage === undefined ||
        withdrawEth.fixed === undefined || withdrawEth.percentage === undefined ||
        withdrawTrx.fixed === undefined || withdrawTrx.percentage === undefined ||
        withdrawUsdtEth.fixed === undefined || withdrawUsdtEth.percentage === undefined ||
        withdrawUsdtTrx.fixed === undefined || withdrawUsdtTrx.percentage === undefined;

    const didChange = props.value?.name !== name ||
        props.value?.webhookUrl !== webhookUrl ||
        props.value?.withdrawFees.bitcoin !== withdrawBtc ||
        props.value?.withdrawFees.usdCoin !== withdrawUsdc ||
        props.value?.withdrawFees.ether !== withdrawEth ||
        props.value?.withdrawFees.trx !== withdrawTrx ||
        props.value?.withdrawFees.tether.ethereum !== withdrawUsdtEth ||
        props.value?.withdrawFees.tether.tron !== withdrawUsdtTrx;

    useEffect(() => {
        if (!props.value)
            return;

        setName(props.value.name);
        setWebhookUrl(props.value.webhookUrl);

        setWithdrawBtc(props.value.withdrawFees.bitcoin);
        setWithdrawUsdc(props.value.withdrawFees.usdCoin);
        setWithdrawEth(props.value.withdrawFees.ether);
        setWithdrawTrx(props.value.withdrawFees.trx);
        setWithdrawUsdtEth(props.value.withdrawFees.tether.ethereum);
        setWithdrawUsdtTrx(props.value.withdrawFees.tether.tron);
    }, [props.value]);

    const handleOnAction = useCallback(
        async () => {
            if (isInvalid || !didChange)
                return;

            const payload: BasePartner = {
                name,
                webhookUrl,
                withdrawFees: {
                    bitcoin: withdrawBtc as Fee,
                    usdCoin: withdrawUsdc as Fee,
                    ether: withdrawEth as Fee,
                    trx: withdrawTrx as Fee,
                    tether: {
                        ethereum: withdrawUsdtEth as Fee,
                        tron: withdrawUsdtTrx as Fee
                    }
                }
            };

            await props.onAction(payload);
        },
        [
            didChange,
            isInvalid,
            name,
            props,
            webhookUrl,
            withdrawBtc,
            withdrawEth,
            withdrawTrx,
            withdrawUsdc,
            withdrawUsdtEth,
            withdrawUsdtTrx
        ]
    );

    return (
        <Card className={styles.formPartner}>
            {secret && props.id === secret.id && (
                <div className={styles.secretNewlyCreated}>
                    <div className={styles.label}>
                        Partner secret: {secret!.secret}
                        <CopyButton label="Secret" value={secret!.secret} />
                    </div>
                    <IconButton icon="close" onClick={() => dispatch(clearRecentlyCreatedSecret())} />
                </div>
            )}
            <HeaderApp title={props.title}>
                {props.children}
            </HeaderApp>
            <div className={styles.form}>
                <h3>General</h3>

                <Field
                    label="Name"
                    placeholder="Stripe"
                    onChange={setName}
                    value={name}
                    className={styles.field}
                />

                <Field
                    label="Webhook Url"
                    placeholder="https://stripe.com/webhook"
                    onChange={setWebhookUrl}
                    value={webhookUrl}
                    className={styles.field}
                />

                <h3>Withdraw Fees</h3>

                <FormFee
                    label="BTC"
                    hint="This fee will be applied on BTC withdraws"
                    value={withdrawBtc}
                    coinLabel="BTC"
                    onChange={setWithdrawBtc} />

                <FormFee
                    label="USDC"
                    hint="This fee will be applied on USDC withdraws"
                    value={withdrawUsdc}
                    coinLabel="USDC"
                    onChange={setWithdrawUsdc} />

                <FormFee
                    label="ETH"
                    hint="This fee will be applied on ETH withdraws"
                    value={withdrawEth}
                    coinLabel="ETH"
                    onChange={setWithdrawEth} />

                <FormFee
                    label="TRX"
                    hint="This fee will be applied on TRX withdraws"
                    value={withdrawTrx}
                    coinLabel="TRX"
                    onChange={setWithdrawTrx} />

                <FormFee
                    label="USDT (ETH)"
                    hint="This fee will be applied on USDT (ETH) withdraws"
                    value={withdrawUsdtEth}
                    coinLabel="USDT"
                    onChange={setWithdrawUsdtEth} />

                <FormFee
                    label="USDT (TRX)"
                    hint="This fee will be applied on USDT (TRX) withdraws"
                    value={withdrawUsdtTrx}
                    coinLabel="USDT"
                    onChange={setWithdrawUsdtTrx} />
            </div>

            <div className={styles.actions}>
                <Button
                    className={styles.button}
                    label={props.labelAction}
                    loading={isLoading}
                    disabled={isInvalid || !didChange}
                    onClick={handleOnAction} />
            </div>
        </Card>
    );
};

interface FormFeeProps {
    label: string;
    hint: string;
    value: OptionalFee;
    onChange: (val: OptionalFee) => void;
    coinLabel: string;
}

export const FormFee: FunctionComponent<FormFeeProps> = props => {
    const handlePercentage = useCallback((val: string) => {
        const nVal = parseFloat(val);
        if (isNaN(nVal)) {
            props.onChange({ fixed: props.value.fixed, percentage: undefined });
            return;
        }

        props.onChange({ fixed: props.value.fixed, percentage: nVal });

    }, [props]);

    const handleFixed = useCallback((val: string) => {
        const nVal = parseFloat(val);
        if (isNaN(nVal)) {
            props.onChange({ fixed: undefined, percentage: props.value.percentage });
            return;
        }

        props.onChange({ fixed: nVal, percentage: props.value.percentage });
    }, [props]);

    return (
        <FormFieldWithHint label={props.label} hint={props.hint}>
            <div className={styles.formFee}>
                <Field
                    label="Fixed"
                    placeholder="8"
                    onChange={handleFixed}
                    value={props.value.fixed?.toString() || ''}
                    className={styles.field}
                    suffix={<span className={styles.suffixField}>{props.coinLabel}</span>}
                />
                <img src={AddIcon} alt="Add" className={styles.addIcon} />
                <Field
                    label="Percentage"
                    placeholder="14"
                    onChange={handlePercentage}
                    value={props.value.percentage?.toString() || ''}
                    className={styles.field}
                    suffix={<span className={styles.suffixField}>%</span>}
                />
            </div>
        </FormFieldWithHint>
    );
};
