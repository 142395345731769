import React, { useCallback } from 'react';
import { createOperator } from '../../../store/operatorSlice';
import { BaseFormUser } from '../../app/base-form-user';
import { useAppDispatch } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { BaseUser } from '../../../types/User';

export const AddOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const goToOperators = () => navigate('/operators');

    const handleCreation = useCallback(async (dto: BaseUser) => {
        await dispatch(createOperator(dto));
        goToOperators();
    }, [dispatch, goToOperators]);

    return (
        <BaseFormUser
            title="Create Operator"
            labelAction="Create"
            onAction={handleCreation}
        />
    );
};
