import { Action, AnyAction, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import authSlice, { loginFromStorage } from './authSlice';
import appSlice from './appSlice';
import clientSlice, { setupClientState } from './clientSlice';
import { Api } from '../services/Api';
import notificationSlice from '../store/notificationSlice';
import depositSlice, { setupDepositState } from '../store/depositSlice';
import statementSlice, { setupStatementState } from '../store/statementSlice';
import withdrawSlice, { setupWithdrawState } from '../store/withdrawSlice';
import ethereumWalletsSlice from './ethereumWalletsSlice';
import tronWalletsSlice from './tronWalletsSlice';
import operatorSlice, { setupOperatorState } from './operatorSlice';
import PartnerSlice, { setupPartnerState } from './partnerSlice';
import { delay } from '../utils';

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        app: appSlice,
        auth: authSlice,
        partner: PartnerSlice,
        withdraw: withdrawSlice,
        notification: notificationSlice,
        client: clientSlice,
        statement: statementSlice,
        deposit: depositSlice,
        operator: operatorSlice,
        ethereumWallets: ethereumWalletsSlice,
        tronWallets: tronWalletsSlice
    }
});

export type AppThunkDispatcher = ThunkDispatch<RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    AnyAction>;
export type AppDispatch = typeof store.dispatch;

export const setupApplication = async (): Promise<void> => {
    Api.setDispatcher(store.dispatch);

    store.dispatch(loginFromStorage());
    await delay(400);

    store.dispatch(setupClientState());
    store.dispatch(setupDepositState());
    store.dispatch(setupOperatorState());
    store.dispatch(setupPartnerState());
    store.dispatch(setupStatementState());
    store.dispatch(setupWithdrawState());
};
