import { Client, WalletAddress } from '../types/Client';
import { Fee } from '../types/Partner';
import { Amount, CoinEnum } from '../types/Amount';
import { Withdraw, WithdrawHistoryEntry, WithdrawStatusEnum } from '../types/Withdraw';
import { getCoinInfo } from './constants';

const numberFormat = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 20 });

export const documentFormatter = (document: string | null | undefined): string => {
    if (!document)
        return '';

    const formatter = {
        11: cpfFormatter,
        14: cnpjFormatter
    }[document.length];

    if (!formatter)
        return document;

    return formatter(document);
};

export const cpfFormatter = (cpf: string | null | undefined): string => {
    if (!cpf)
        return '';

    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
};

export const cnpjFormatter = (cnpj: string | null | undefined): string => {
    if (!cnpj)
        return '';

    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(-2)}`;
};

export const labelClient = (client: Client | undefined): string => {
    if (!client)
        return '';

    return client.name || documentFormatter(client.document);
};


export const formatCryptoAddress = (address: WalletAddress | undefined): string => {
    if (!address)
        return '';

    const labelCoin = getCoinInfo(address.coin)?.label || address.coin;

    return `${labelCoin} - ${address.network} - ${address.address}`;
};

export const phoneFormatter = (phoneNumber: string): string => {
    const num = phoneNumber.replace(/^55/, '');
    return `(${num.slice(0, 2)}) ${num.slice(2, 3)} ${num.slice(3, 7)}-${num.slice(-4)}`;
};

export const formatFee = (fee: Fee): string =>
    `${fee.fixed} and ${fee.percentage}%`;

export const formatValue = (coin: CoinEnum, value: number): string =>
    formatAmount({ coin, value });

export const formatAmount = (amount: Amount): string =>
    `${getCoinInfo(amount.coin).symbol} ${numberFormat.format(Math.abs(amount.value))}`;

const withdrawStatusOptions = new Map<WithdrawStatusEnum, (obj: Withdraw | WithdrawHistoryEntry) => string>();
withdrawStatusOptions.set(WithdrawStatusEnum.pending, () => 'Pending');
withdrawStatusOptions.set(WithdrawStatusEnum.doing, ({ operator }) => `Assigned to ${operator?.name}`);
withdrawStatusOptions.set(
    WithdrawStatusEnum.done,
    obj => `Done by ${obj.operator?.name} at ${obj.createdAt.toLocaleString()}`,
);

export const labelWithdrawStatus = (obj: Withdraw | WithdrawHistoryEntry): string =>
    withdrawStatusOptions.get(obj.status)!(obj);
