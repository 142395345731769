import { FunctionComponent, useCallback } from 'react';
import { FormPartner } from './form-partner';
import { BasePartner } from '../../types/Partner';
import { useAppDispatch } from '../../hooks';
import { createPartner } from '../../store/partnerSlice';
import { useNavigate } from 'react-router-dom';

export const AddPartner: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleCreate = useCallback(async (dto: BasePartner) => {
        await dispatch(createPartner(dto));
        navigate('/partners/edit');
    }, [dispatch, navigate]);

    return (
        <FormPartner
            title="Add Partner"
            labelAction="Create"
            onAction={handleCreate}
        />
    );
};
