import './field.scss';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState, } from 'react';

interface Props {
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    label: string;
    placeholder: string;
    value?: string;
    onChange: (val: string) => void;
    disabled?: boolean;
    obscureText?: boolean;
    className?: string;
}

export const Field: React.FC<Props> = (props) => {
    const inputElement = useRef<HTMLInputElement>(null);
    const [val, setVal] = useState('');

    useEffect(() => {
        if (!props.value || val === props.value) {
            return;
        }

        setVal(props.value);
    }, [props.value, val, setVal]);

    const handleChange = useCallback((el: ChangeEvent<HTMLInputElement>) => {
        const { value } = el.target;

        setVal(value);
        props.onChange(value);
    }, [props]);

    const styleHasValue = !!val ? 'has-value' : '';
    const styleDisabled = props.disabled ? 'disabled' : '';

    return (
        <div className={`field ${styleHasValue} ${styleDisabled} ${props.className}`}>
            <label onClick={() => inputElement.current!.focus()}>
                {props.label}
            </label>
            {props.prefix}
            <input
                ref={inputElement}
                type={props.obscureText ? 'password' : ''}
                className="input"
                placeholder={props.placeholder}
                onChange={handleChange}
                disabled={props.disabled}
                value={val}
            />
            {props.suffix}
        </div>
    );
};
