import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styles from './login.module.scss';
import Logo from '../../../assets/images/logo.png';
import { Button } from '../../utils/button';
import { LoginRequest } from '../../../types/User';
import {
    create2FA,
    getAuthIsLoading,
    getErrorAuth,
    getLoginIsStarted,
    login,
    updateLoginError,
} from '../../../store/authSlice';
import useKeyPress from '../../../hooks/useKeyPress';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Field } from '../../utils/field';
import { FieldMasked } from '../../utils/field-masked';
import { getErrorMessage } from '../../../utils/errorMessage';

type HandleChange = <T>(callback: Dispatch<SetStateAction<T>>) => (value: T) => void;

export function Login() {
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(getAuthIsLoading);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState<string>('');

    const loginStarted = useAppSelector(getLoginIsStarted);
    const errorLogin = useAppSelector(getErrorAuth);
    const isDisabled = !email || !password || (!twoFactorCode && loginStarted);

    const requestTwoFactorCode = useCallback(() => {
        dispatch(create2FA(email, password));
    }, [dispatch, email, password]);

    const requestLogin = useCallback(() => {
        const payload: LoginRequest = {
            email,
            password,
            twoFactorAuthCode: Number(twoFactorCode)!,
        };

        dispatch(login(payload));
    }, [dispatch, email, password, twoFactorCode]);

    const action = loginStarted ? requestLogin : requestTwoFactorCode;

    useKeyPress('Enter', () => {
        if (isDisabled)
            return;

        action();
    }, [isDisabled, loginStarted, requestLogin, requestTwoFactorCode]);

    const handleChange: HandleChange = callback => value => {
        dispatch(updateLoginError(''));

        callback(value);
    };

    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <img src={Logo} alt="logo" />
            </div>

            {!loginStarted && (
                <>
                    <Field
                        label="Email"
                        placeholder="jhondoe@gmail.com"
                        value={email}
                        onChange={handleChange(setEmail)}
                        disabled={loginStarted}
                    />
                    <Field
                        label="Password"
                        placeholder="not-a-password"
                        obscureText={true}
                        value={password}
                        onChange={handleChange(setPassword)}
                        disabled={loginStarted}
                    />
                </>
            )}

            {loginStarted && (
                <FieldMasked
                    value={twoFactorCode}
                    onChange={setTwoFactorCode}
                    label="Two Factor Code"
                    placeHolder="142312"
                    formatter={x => x}
                    validator={x => x.length === 6} />
            )}

            <Button
                label="Sign In"
                onClick={action}
                disabled={isDisabled}
                className={styles.signInButton}
                loading={isLoading}
            />

            {loginStarted && (
                <Button
                    label="Did not get a verification code?"
                    onClick={() => dispatch(create2FA(email, password))}
                    appearance={'text'}
                />
            )}
            {!!errorLogin && (
                <div className={styles.alert}>{getErrorMessage(errorLogin)}</div>
            )}
        </div>
    );
}
