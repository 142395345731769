import React, { useState } from 'react';
import { Field } from '../utils/field';
import { Button } from '../utils/button';
import { Dialog } from '../utils/dialog';
import { ChangePasswordDto } from '../../types/ChangePasswordDto';
import styles from './change-password.module.scss';
import {
    changePassword,
    closeChangePasswordDialog,
    getChangePasswordIsOpened,
    getPasswordMustBeChanged,
} from '../../store/authSlice';
import UserService from '../../services/UserService';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FieldMasked } from '../utils/field-masked';

export const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const changePasswordIsOpened = useAppSelector(getChangePasswordIsOpened);
    const passwordMustBeChanged = useAppSelector(getPasswordMustBeChanged);

    const [continueChangePassword, setContinueChangePassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState<string>();

    const isInvalidNewPassword = !!newPassword && !!currentPassword && newPassword === currentPassword;
    const isInvalidConfirmNewPassword = newPassword !== confirmNewPassword;

    const isContinueDisabled = !currentPassword || !newPassword || !confirmNewPassword || isInvalidNewPassword || isInvalidConfirmNewPassword;
    const isUpdateDisabled = !twoFactorCode;

    const changePasswordForm: ChangePasswordDto = {
        currentPassword,
        newPassword,
        confirmNewPassword,
        twoFactorCode: Number(twoFactorCode)
    };

    const continueDialog = () => {
        setContinueChangePassword(true);
        UserService.newTwoFA();
    };

    const closeDialog = passwordMustBeChanged ? undefined : () =>
        dispatch(closeChangePasswordDialog());

    return (
        <Dialog
            isOpen={changePasswordIsOpened}
            onClose={closeDialog}
            title="Change Password"
            className={styles.changePassword}
        >
            {!continueChangePassword && (
                <div>
                    <Field
                        label="Current Password"
                        placeholder="********"
                        obscureText={true}
                        onChange={setCurrentPassword}
                        value={currentPassword}
                        className={styles.field}
                    />
                    <Field
                        label="New Password"
                        placeholder="********"
                        obscureText={true}
                        onChange={setNewPassword}
                        value={newPassword}
                        className={styles.field}
                    />
                    {isInvalidNewPassword && (
                        <span className={styles.error}>Current and new passwords must be different!</span>
                    )}
                    <Field
                        label="Confirm New Password"
                        placeholder="********"
                        obscureText={true}
                        onChange={setConfirmNewPassword}
                        value={confirmNewPassword}
                        className={styles.field}
                    />
                </div>
            )}

            {continueChangePassword && (
                <FieldMasked
                    label="Two Factor Code"
                    placeHolder="142312"
                    value={twoFactorCode}
                    onChange={setTwoFactorCode}
                    formatter={x => x}
                    validator={x => x.length === 6} />
            )}

            <div className={styles.actions}>
                {!!closeDialog && (
                    <Button
                        label="Cancel"
                        appearance="outline"
                        onClick={closeDialog} />
                )}
                {!continueChangePassword && (
                    <Button
                        label="Continue"
                        onClick={() => continueDialog()}
                        disabled={isContinueDisabled} />
                )}
                {continueChangePassword && (
                    <Button
                        label="Update"
                        onClick={() => dispatch(changePassword(changePasswordForm))}
                        disabled={isUpdateDisabled} />
                )}
            </div>
        </Dialog>
    );
};
