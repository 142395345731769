import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Eye from '../../assets/images/icons/eye.svg';
import { useAppDispatch } from '../../hooks';
import { downloadClients, getLstClients, getTotalClients } from '../../store/clientSlice';
import { BasePagination, WithData } from '../../types';
import { Card } from '../utils/card';
import { DropdownItem, Dropdowns } from '../utils/dropdowns';
import { HeaderApp } from '../utils/header';
import styles from './clients.module.scss';
import { IconButton } from '../utils/icon-button';
import { Paginator } from '../utils/paginator';
import { documentFormatter } from '../../utils/formatters';
import { Client } from '../../types/Client';
import { basicPagination } from '../../utils/pagination';

const DropdownClient: FunctionComponent<WithData<Client>> = ({ data }) => {
    const navigate = useNavigate();

    const showDetailOption: DropdownItem = {
        icon: Eye,
        onClick: () => navigate(`/clients/${data.id}`),
        label: 'Details',
    };

    return <Dropdowns options={[showDetailOption]} />;
};

const ClientComponent: FunctionComponent<WithData<Client>> = ({ data }) => {
    const navigate = useNavigate();

    const openClient = () => navigate(`/clients/${data.id}`);

    return (
        <tr>
            <td
                data-title="Document"
                onClick={openClient}>{!!data.document ? documentFormatter(data.document) : '-'}</td>
            <td data-title="Name" onClick={openClient}>{data.name}</td>
            <td data-title="Birth Date" onClick={openClient}>{data.birthDate?.toISOString().slice(10)}</td>
            <td data-title="Created At" onClick={openClient}>{data.createdAt.toLocaleString()}</td>
            <td>
                <DropdownClient data={data} />
            </td>
        </tr>
    );
};

export const Clients = () => {
    const dispatch = useAppDispatch();
    const [pagination, setPagination] = useState<BasePagination>(basicPagination);

    const lst = useSelector(getLstClients(pagination));
    const total = useSelector(getTotalClients);

    return (
        <Card className={styles.clients}>
            <HeaderApp title="Clients">
                <IconButton icon="analytics" onClick={() => dispatch(downloadClients())} />
            </HeaderApp>
            <div className={styles.tableClients}>
                <table>
                    <thead>
                    <tr>
                        <th>Document</th>
                        <th>Name</th>
                        <th>Birth Date</th>
                        <th>Created At</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {lst?.map(x => (
                        <ClientComponent key={x.id} data={x} />
                    ))}
                    </tbody>
                </table>
                <Paginator pagination={pagination} totals={total} setPage={setPagination} />
            </div>
        </Card>
    );
};
