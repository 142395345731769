import React, { FunctionComponent } from 'react';
import { Withdraw, WithdrawStatusEnum } from '../../types/Withdraw';
import { Button } from '../utils/button';
import { getWithdrawIsLoading, openMarkAsCompleted, updateWithdrawStatus } from '../../store/withdrawSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface Props {
    withdraw: Withdraw,
}

export const ActionWithdraw: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getWithdrawIsLoading(props.withdraw.id));

    if (props.withdraw.status === WithdrawStatusEnum.done)
        return null;

    if (props.withdraw.status === WithdrawStatusEnum.pending) {
        const action = () => dispatch(updateWithdrawStatus(props.withdraw, WithdrawStatusEnum.doing));
        return <Button
            label="Assign to Me"
            appearance="outline"
            loading={isLoading}
            onClick={action} />;
    }

    const action = () => dispatch(openMarkAsCompleted(props.withdraw));
    return <Button label="Mark as Completed" loading={isLoading} onClick={action} />;
};
