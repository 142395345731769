import React, { useState } from 'react';
import { Dialog } from '../../utils/dialog';
import {
    closePopups,
    getMarkAsCompleteIsOpen,
    getSelectedWithdraw,
    updateWithdrawStatus
} from '../../../store/withdrawSlice';
import { Button } from '../../utils/button';
import { WithdrawStatusEnum } from '../../../types/Withdraw';
import { Field } from '../../utils/field';
import styles from './mark-as-completed.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';

export const MarkAsCompleted = () => {
    const [externalIdentifier, setExternalIdentifier] = useState('');
    const dispatch = useAppDispatch();
    const handleClose = () => dispatch(closePopups());

    const isOpen = useAppSelector(getMarkAsCompleteIsOpen);
    const selectedWithdraw = useAppSelector(getSelectedWithdraw);

    if (!selectedWithdraw) {
        return null;
    }

    const updateStatus = () =>
        dispatch(updateWithdrawStatus(selectedWithdraw, WithdrawStatusEnum.done, externalIdentifier));

    return (
        <Dialog
            className={styles.markAsCompletedWithdraw}
            title="Mark as Completed"
            isOpen={isOpen}
            onClose={handleClose}>
            <Field
                label="Transaction Identifier"
                placeholder="E00000000202107051330ONN1UNDVHJB"
                value={externalIdentifier}
                onChange={setExternalIdentifier} />
            <Button label="Complete" onClick={updateStatus} disabled={!externalIdentifier} />
        </Dialog>
    );
};
