import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { ConfirmationParams } from '../types';
import { cleanClientStateAndStorage } from './clientSlice';
import { cleanDepositStateAndStorage } from './depositSlice';
import { cleanOperatorStateAndStorage } from './operatorSlice';
import { cleanPartnerStateAndStorage } from './partnerSlice';
import { cleanStatementStateAndStorage } from './statementSlice';
import { cleanWithdrawStateAndStorage } from './withdrawSlice';
import { cleanAuthStorageAndLogoff } from './authSlice';

interface AppState {
    confirm: ConfirmationParams | null;
    aboutSectionIsOpened: boolean;
    menuIsOpened: boolean;
    profileIsOpened: boolean;
    isAggregationOpened: boolean;
}

const initialState: AppState = {
    confirm: null,
    aboutSectionIsOpened: false,
    menuIsOpened: false,
    profileIsOpened: false,
    isAggregationOpened: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAboutSectionIsOpened: (state, action: PayloadAction<boolean>) => {
            state.aboutSectionIsOpened = action.payload;
        },
        openConfirmation: (state, action: PayloadAction<ConfirmationParams>) => {
            state.confirm = action.payload;
        },
        closeConfirmation: state => {
            state.confirm = null;
        },
        toggleMenuIsOpen: state => {
            state.menuIsOpened = !state.menuIsOpened;
        },
        toggleProfileOpen: state => {
            state.profileIsOpened = !state.profileIsOpened;
        },
        setIsAggregationOpened: (state, action: PayloadAction<boolean>) => {
            state.isAggregationOpened = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    setAboutSectionIsOpened,
    openConfirmation,
    closeConfirmation,
    resetState,
    toggleMenuIsOpen,
    toggleProfileOpen,
    setIsAggregationOpened,
} = appSlice.actions;


export const getAboutSectionIsOpened = (state: RootState): boolean => state.app.aboutSectionIsOpened;
export const getConfirmation = (state: RootState): ConfirmationParams | null => state.app.confirm;
export const getMenuIsOpen = (state: RootState): boolean => state.app.menuIsOpened;
export const getConfirmationIsOpen = (state: RootState): ConfirmationParams | null => state.app.confirm;
export const getProfileIsOpen = (state: RootState): boolean => state.app.profileIsOpened;
export const getIsAggregationOpened = (state: RootState): boolean => state.app.isAggregationOpened;

export const cleanLocalStorageAndLogoff = (): AppThunk<Promise<void>> => async dispatch => {
    dispatch(resetState());

    dispatch(cleanClientStateAndStorage());
    dispatch(cleanDepositStateAndStorage());
    dispatch(cleanOperatorStateAndStorage());
    dispatch(cleanPartnerStateAndStorage());
    dispatch(cleanStatementStateAndStorage());
    dispatch(cleanWithdrawStateAndStorage());

    dispatch(cleanAuthStorageAndLogoff());
};

export default appSlice.reducer;
