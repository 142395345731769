import { Deposit } from '../types/Deposit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Columns, downloadCsv } from '../utils/report';
import { documentFormatter, formatAmount } from '../utils/formatters';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveCustomLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { Websocket } from '../services/Websocket';

const DEPOSITS_KEY = 'deposits';
const DEPOSITS_LAST_UPDATED_DATE_KEY = 'deposits_last_updated';

interface DepositState {
    deposits: Deposit[];
}

const initialState: DepositState = {
    deposits: []
};

const depositSlice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {
        updateDepositList: (state, action: PayloadAction<Deposit[]>) => {
            state.deposits = action.payload;
        },
        upsertDeposit: (state, action: PayloadAction<Deposit>) => {
            state.deposits = upsertOnList(state.deposits, action.payload);
        }
    }
});

export const {
    updateDepositList,
    upsertDeposit
} = depositSlice.actions;

export const downloadDepositsList = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().deposit.deposits;
    const partners = getState().partner.partners;

    const getPartnerName = (partnerId: string): string => {
        const partner = partners.find(x => x.id === partnerId);
        return partner ? partner.name : 'Unknown';
    };

    const columns: Columns<Deposit> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Client Id', getValue: x => x.client.id },
        { title: 'Client Document', getValue: x => documentFormatter(x.client.document) },
        { title: 'Partner Name', getValue: x => getPartnerName(x.client.partnerId) },
        { title: 'Amount', getValue: x => formatAmount(x.amount) },
        { title: 'Created At', getValue: x => x.createdAt.toLocaleString() },
    ];

    downloadCsv(lst, columns, 'deposits.csv');
};

export const cleanDepositStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(updateDepositList([]));
    removeOnLocalStorage(DEPOSITS_KEY);
    removeOnLocalStorage(DEPOSITS_LAST_UPDATED_DATE_KEY);
};

export const setupDepositState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Deposit>('update-deposit', (deposit: Deposit) => {
        dispatch(upsertDeposit(deposit));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(DEPOSITS_LAST_UPDATED_DATE_KEY);
    Websocket.emitOnConnection('subscribe-deposit', payload);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().deposit.deposits;
    saveOnLocalStorage(DEPOSITS_KEY, lst);
    saveCustomLastUpdatedDate(DEPOSITS_LAST_UPDATED_DATE_KEY, lst, x => x.createdAt);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Deposit[]>(DEPOSITS_KEY);

    if (!lst)
        return;

    dispatch(updateDepositList(lst));
};

export const getDeposit = (id: string, state: RootState): Deposit | undefined =>
    state.deposit.deposits.find(x => x.id === id);

export default depositSlice.reducer;
