import { CreateUserDto, LoginRequest, LoginResponse, UpdateMySelfDto, UpdateUserDto, User } from '../types/User';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { ChangePasswordDto } from '../types/ChangePasswordDto';
import { CreatePartnerDto, HasSecretDto, UpdatePartnerDto } from '../types/Partner';

const baseUrl = process.env.REACT_APP_USER_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class UserService {
    public static login = (obj: LoginRequest): Promise<LoginResponse> => {
        const request: LoginRequest = {
            ...obj,
            email: obj.email.toLowerCase(),
        };
        return Api.post<LoginResponse>(generateUrl('login'), request);
    };

    public static create2FA = (email: string, password: string): Promise<void> => {
        const payload = {
            email: email.toLowerCase(),
            password,
        };

        return Api.post(generateUrl('login', 'create2FA'), payload);
    };

    public static renewToken = (): Promise<void> =>
        Api.get(generateUrl('login', 'renewToken'));

    public static changePassword = (payload: ChangePasswordDto): Promise<void> =>
        Api.post(generateUrl('resetPassword', 'changePasswordUser'), payload);

    public static resetUserPassword = (userId: string): Promise<void> =>
        Api.post(generateUrl('resetPassword', userId));

    public static updateMySelf = (dto: UpdateMySelfDto): Promise<void> =>
        Api.put(generateUrl('backoffice-user', 'my-self'), dto);

    public static newTwoFA = (): Promise<void> =>
        Api.post(generateUrl('twoFactorAuth'));

    public static update = (id: string, user: Partial<User>): Promise<void> =>
        Api.put(generateUrl(id), user);

    public static createOperator = (dto: CreateUserDto): Promise<void> =>
        Api.post(generateUrl('operator'), dto);

    public static updateOperator = (dto: UpdateUserDto): Promise<void> =>
        Api.put(generateUrl('operator'), dto);

    public static deleteOperator = (id: string): Promise<void> =>
        Api.delete(generateUrl('operator', id));

    public static createPartner = (dto: CreatePartnerDto): Promise<HasSecretDto> =>
        Api.post(generateUrl('partner'), dto);

    public static updatePartner = (dto: UpdatePartnerDto): Promise<void> =>
        Api.put(generateUrl('partner'), dto);

    public static deletePartner = (id: string): Promise<void> =>
        Api.delete(generateUrl('partner', id));

    public static createSecret = (id: string): Promise<HasSecretDto> =>
        Api.post(generateUrl('partner', 'generateSecret', id));
}
