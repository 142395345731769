import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EthereumGatewayService from '../services/EthereumGatewayService';
import { Client } from '../types/Client';
import { AppThunk, RootState } from './index';
import { showAlert } from './notificationSlice';
import { EthereumAddress, EthereumAddresses, EthereumAddressWithClient } from '../types/EthereumAddresses';
import { EthereumStandaloneTransferRequest } from '../types/EthereumTransfer';
import { CoinEnum } from '../types/Amount';

interface EthereumGatewayState {
    addresses: EthereumAddresses | null;
    selectedAddress: EthereumAddress | null;
    isTransferLoading: boolean;
    transactionId: string | null;
}

const initialState: EthereumGatewayState = {
    addresses: null,
    selectedAddress: null,
    isTransferLoading: false,
    transactionId: null,
};

const ethereumWalletsSlice = createSlice({
    name: 'ethereumGateway',
    initialState,
    reducers: {
        updateEthereumBalances: (state, action: PayloadAction<EthereumAddresses>) => {
            state.addresses = action.payload;
        },
        clearEthereumBalances: (state) => {
            state.addresses = null;
        },
        openEthereumTransferPopUp: (state, action: PayloadAction<EthereumAddress>) => {
            state.selectedAddress = action.payload;
        },
        closeEthereumTransferPopUp: (state) => {
            state.selectedAddress = null;
            state.transactionId = null;
        },
        setTransferLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransferLoading = action.payload;
        },
        setTransactionId: (state, action: PayloadAction<string>) => {
            state.transactionId = action.payload;
        },
    },
});

export const {
    updateEthereumBalances,
    clearEthereumBalances,
    openEthereumTransferPopUp,
    closeEthereumTransferPopUp,
    setTransferLoading,
    setTransactionId,
} = ethereumWalletsSlice.actions;

export const fetchEthereumBalances = (): AppThunk => dispatch =>
    EthereumGatewayService.listBalances()
        .then(async lst => {
            dispatch(updateEthereumBalances(lst));
        });

export const makeEthereumTransfer = (req: EthereumStandaloneTransferRequest): AppThunk => dispatch => {
    dispatch(setTransferLoading(true));
    EthereumGatewayService.transfer(req)
        .then(x => {
            dispatch(setTransactionId(x.txId));
            dispatch(showAlert('Transfer created'));
        })
        .finally(() => {
            dispatch(setTransferLoading(false));
        });
};

export const aggregateOnEth = (minimum: number, coin: CoinEnum, address: string): AppThunk<Promise<void>> => dispatch =>
    EthereumGatewayService.aggregate(minimum, coin, address)
        .then(() => {
            dispatch(showAlert('Aggregation made'));
        });

export const getEthereumAddresses = (state: RootState): EthereumAddresses | null => state.ethereumWallets.addresses;
export const getSelectedEthereumAddress = (state: RootState): EthereumAddress | null => state.ethereumWallets.selectedAddress;
export const getEthereumTransferIsLoading = (state: RootState): boolean => state.ethereumWallets.isTransferLoading;
export const getEthereumTransactionId = (state: RootState): string | null => state.ethereumWallets.transactionId;
export const getEthereumAddressesWithClients = (state: RootState): EthereumAddressWithClient[] => {
    const { addresses } = state.ethereumWallets;
    if (!addresses)
        return [];

    const clients = state.client.lstClients;

    const findClient = (id: string | undefined): Client | undefined =>
        clients.find(x => x.id === id);

    return addresses.map(address => ({
        ...address,
        client: findClient(address.clientId)
    }));
};


export default ethereumWalletsSlice.reducer;
