import React from 'react';
import { Dialog } from '../../utils/dialog';
import { closePopups, getDetailIsOpen, getSelectedWithdraw } from '../../../store/withdrawSlice';
import { Label } from '../../utils/label';
import styles from './withdraw-detail.module.scss';
import { CopyButton } from '../../utils/copy-button';
import { ActionWithdraw } from '../ActionWithdraw';
import { WithdrawHistory } from '../withdraw-history';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useSelector } from 'react-redux';
import { formatValue, labelWithdrawStatus } from '../../../utils/formatters';
import { getPartner } from '../../../store/partnerSlice';

export const WithdrawDetail = () => {
    const selectedWithdraw = useSelector(getSelectedWithdraw);
    const partner = useAppSelector(getPartner(selectedWithdraw?.client.partnerId));
    const isOpen = useSelector(getDetailIsOpen);

    const dispatch = useAppDispatch();
    const handleClose = () => dispatch(closePopups());

    if (!selectedWithdraw)
        return null;

    const { coin, amountToReceive, fee } = selectedWithdraw;

    return (
        <Dialog className={styles.withdrawDetail} title="Withdraw" isOpen={isOpen} onClose={handleClose}>
            <div className={styles.info}>
                <Label
                    text="Partner"
                    content={partner?.name}
                />
                <Label text="Document" content={selectedWithdraw.client.document} />
                <Label text="Amount">
                    {formatValue(coin, amountToReceive)}
                    <CopyButton styleWeight="slim" value={formatValue(coin, amountToReceive)} label="Amount" />
                </Label>
                <Label
                    text="Status"
                    content={labelWithdrawStatus(selectedWithdraw)}
                />
                <Label text="Fee" content={formatValue(coin, fee)} />
                <Label text="Wallet">
                    {selectedWithdraw.wallet}
                    <CopyButton styleWeight="slim" label="Crypto Wallet" value={selectedWithdraw.wallet} />
                </Label>
            </div>

            <WithdrawHistory withdraw={selectedWithdraw} />
            <ActionWithdraw withdraw={selectedWithdraw} />
        </Dialog>
    );
};
