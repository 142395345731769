import { useAppDispatch, useAppSelector } from '../../hooks';
import { getIsAggregationOpened, setIsAggregationOpened } from '../../store/appSlice';
import { CoinEnum, NetworkEnum } from '../../types/Amount';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { aggregateOnEth, getEthereumTransferIsLoading, } from '../../store/ethereumWalletsSlice';
import { SearchEthereumAddress } from '../ethereum-wallets/search-ethereum-address';
import { Dialog } from '../utils/dialog';
import styles from './aggregate-wallets-balance.module.scss';
import { Field } from '../utils/field';
import { IconButton } from '../utils/icon-button';
import { SelectField } from '../utils/select-field';
import { aggregateOnTron } from '../../store/tronWalletsSlice';
import { useNavigate } from 'react-router-dom';
import { SearchTronAddress } from '../tron-wallets/search-tron-address';
import { coinOptions } from '../../utils/constants';

interface Props {
    network: NetworkEnum;
}

const allowedCoins = {
    [NetworkEnum.ethereum]: [CoinEnum.usdc, CoinEnum.usdt],
    [NetworkEnum.tron]: [CoinEnum.usdt],
    [NetworkEnum.bitcoin]: [] as CoinEnum[],
};
const getCoinEnumOptions = (network: NetworkEnum) => coinOptions
    .filter(x => allowedCoins[network].includes(x.id))
    .map(x => ({
        label: x.label,
        value: x.id,
    }));

export const AggregateWalletsBalance: FunctionComponent<Props> = ({ network }) => {
    const isOpened = useAppSelector(getIsAggregationOpened);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const coinEnumOptions = getCoinEnumOptions(network);
    const defaultCoin = allowedCoins[network][0];

    const [coin, setCoin] = useState<CoinEnum>(defaultCoin);
    const [address, setAddress] = useState<string>();
    const [minimum, setMinimum] = useState<number>();
    const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setCoin(defaultCoin);
        setAddress(undefined);
        setMinimum(undefined);
        setSearchIsOpen(false);
    }, [defaultCoin, isOpened]);

    const aggregate = useCallback(async () => {
        const aggregateFn = {
            [NetworkEnum.ethereum]: aggregateOnEth,
            [NetworkEnum.tron]: aggregateOnTron,
            [NetworkEnum.bitcoin]: undefined,
        }[network];

        if (!aggregateFn)
            throw new Error(`Aggregate function not found for network ${network}`);

        await dispatch(aggregateFn(minimum!, coin, address!));
        navigate(0);
    }, [address, coin, dispatch, minimum, navigate, network]);

    const isLoading = useSelector(getEthereumTransferIsLoading);
    const actionDisabled: boolean =
        !address ||
        !coin ||
        !minimum;

    const closeSearch = useCallback(() => setSearchIsOpen(false), []);

    if (searchIsOpen) {
        const SearchComponent = {
            [NetworkEnum.ethereum]: SearchEthereumAddress,
            [NetworkEnum.tron]: SearchTronAddress,
            [NetworkEnum.bitcoin]: undefined,
        }[network];

        if (!SearchComponent)
            throw new Error(`Search component not found for network ${network}`);

        return (
            <SearchComponent
                isOpen={isOpened}
                onCancel={closeSearch}
                targetAddress={address}
                onChosenAddress={(address) => {
                    closeSearch();
                    setAddress(address);
                }} />
        );
    }

    return (
        <Dialog
            isOpen={isOpened}
            className={styles.aggregateWalletsBalance}
            title="Aggregate"
            subtitle="All wallets"
            onClose={() => dispatch(setIsAggregationOpened(false))}
            action="Send all"
            actionDisabled={actionDisabled}
            onAction={aggregate}
            loading={isLoading}
        >
            <div className={styles.form}>
                <Field
                    className={styles.field}
                    label="Target"
                    placeholder="0x"
                    value={address}
                    onChange={setAddress}
                    suffix={<IconButton icon="search" onClick={() => setSearchIsOpen(true)} />}
                />
                <SelectField
                    options={coinEnumOptions}
                    onChange={setCoin}
                    value={coin}
                    label="Coin"
                />
                <Field
                    className={styles.field}
                    label="Amount"
                    placeholder="0.1"
                    value={minimum?.toString()}
                    onChange={val => setMinimum(parseFloat(val))}
                />
            </div>
        </Dialog>
    );
};
