import { Amount, CoinEnum } from './Amount';

export enum TransactionTypeEnum {
    deposit = 'deposit',
    withdraw = 'withdraw',
    creditAnnotation = 'creditAnnotation',
    fundAccount = 'fundAccount',
}

export interface BaseTransaction {
    id: string;
    type: TransactionTypeEnum;
    clientId: string;
    partnerId: string;
    createdAt: Date;
}

export interface DepositTransaction extends BaseTransaction {
    type: TransactionTypeEnum.deposit;
    coin: CoinEnum;
    amount: number;
}

export interface WithdrawTransaction extends BaseTransaction {
    type: TransactionTypeEnum.withdraw;
    coin: CoinEnum;
    fee: number;
    amountRequested: number;
    amountReceived: number;
}

export interface CreditAnnotationTransaction extends BaseTransaction {
    type: TransactionTypeEnum.creditAnnotation;
    coin: CoinEnum;
    amount: number;
}

export interface FundAccountTransaction extends BaseTransaction {
    type: TransactionTypeEnum.fundAccount;
    amounts: Amount[];
}

export type StatementTransaction =
    | WithdrawTransaction
    | DepositTransaction
    | CreditAnnotationTransaction
    | FundAccountTransaction;

