import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {
    clearTronState,
    fetchBalance,
    fetchWallets,
    getAddressesWithClients,
    getTronBalance,
    openTransferPopUp,
} from '../../store/tronWalletsSlice';
import { WithData } from '../../types';
import { Card } from '../utils/card';
import { CopyButton } from '../utils/copy-button';
import { HeaderApp } from '../utils/header';
import styles from './tron-wallets.module.scss';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { TransferTron } from './transfer-tron';
import { IconButton } from '../utils/icon-button';
import { SuccessTransferTron } from './success-transfer-tron';
import { TronAddress, TronAddressWithClient } from '../../types/TronAddress';
import { Amount, CoinEnum, NetworkEnum } from '../../types/Amount';
import { formatAmount, labelClient } from '../../utils/formatters';
import { setIsAggregationOpened } from '../../store/appSlice';
import { AggregateWalletsBalance } from '../aggregate-wallets-balance';

export const TronWallets = () => {
    const lst = useSelector(getAddressesWithClients);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchWallets());

        return () => {
            clearTronState();
        };
    }, []);

    if (!lst)
        return (<div>loading...</div>);

    return (
        <>
            <Card className={styles.tronWallets}>
                <HeaderApp title="Tron Wallets">
                    <IconButton icon="merge" onClick={() => dispatch(setIsAggregationOpened(true))} />
                </HeaderApp>
                <div className={styles.tableWallets}>
                    <table>
                        <thead>
                        <tr>
                            <th>Client</th>
                            <th>Address</th>
                            <th>TRX</th>
                            <th>USDT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lst.map((x, key) => (
                            <TronWalletItem data={x} key={key} />
                        ))}
                        </tbody>
                    </table>
                </div>
            </Card>
            <TransferTron />
            <SuccessTransferTron />
            <AggregateWalletsBalance network={NetworkEnum.tron} />
        </>
    );
};

const TronWalletItem: FunctionComponent<WithData<TronAddressWithClient>> = ({ data }) => {
    const dispatch = useAppDispatch();

    const balances = useSelector(getTronBalance(data));
    const { clientId, address } = data;

    const trxBalance = balances.find(x => x.coin === CoinEnum.trx)!;
    const usdtBalance = balances.find(x => x.coin === CoinEnum.usdt)!;

    return (
        <tr className={!!clientId ? '' : styles.main}>
            <td data-title="Client">{labelClient(data.client)}</td>
            <td data-title="Address">
                <div className={styles.address}>
                    <span>{address}</span>
                    <CopyButton label="TRON address" value={address} />
                </div>
            </td>
            <td data-title="TRX">
                <TronAmount amount={trxBalance} wallet={data} />
            </td>
            <td data-title="USDT">
                <TronAmount amount={usdtBalance} wallet={data} />
            </td>
            <td>
                <IconButton icon={'share'} onClick={() => dispatch(openTransferPopUp(data))} />
            </td>
        </tr>
    );
};

interface TronAmountProps {
    amount: Amount;
    wallet: TronAddress;
}

const TronAmount: FunctionComponent<TronAmountProps> = ({ amount, wallet }) => {
    const dispatch = useAppDispatch();

    const loadBalance = useCallback(() => {
        dispatch(fetchBalance(wallet));
    }, [wallet]);

    if (!amount)
        return (
            <div className={styles.amount}>
                <IconButton icon={'eye'} onClick={loadBalance} />
            </div>
        );

    return (
        <div className={styles.amount}>
            {formatAmount(amount)}
            <IconButton icon="replay" onClick={loadBalance} />
        </div>
    );
};
