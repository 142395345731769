import React from 'react';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { getMySelf, updateMySelfInfo } from '../../store/operatorSlice';
import { BaseFormUser } from '../app/base-form-user';

export const MySelf = () => {
    const dispatch = useAppDispatch();
    const mySelf = useSelector(getMySelf);

    if (!mySelf)
        return <div>loading...</div>;

    return (
        <BaseFormUser
            title="My Information"
            labelAction="Update"
            onAction={dto => dispatch(updateMySelfInfo(dto))}
            value={mySelf}
        />
    );
};
