import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { BaseUser } from '../../../types/User';
import { Field } from '../../utils/field';
import styles from './base-form-user.module.scss';
import { FieldPhoneNumber } from '../../utils/field-phone-number';
import { Card } from '../../utils/card';
import { HeaderApp } from '../../utils/header';
import { Button } from '../../utils/button';
import { useAppSelector } from '../../../hooks';
import { getOperatorIsLoading } from '../../../store/operatorSlice';

interface Props {
    title: string;
    value?: BaseUser,
    labelAction: string;
    onAction: (data: BaseUser) => Promise<void>;
    children?: React.ReactNode;
}

export const BaseFormUser: FunctionComponent<Props> = props => {
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');

    const isLoading = useAppSelector(getOperatorIsLoading);

    useEffect(() => {
        if (!props.value)
            return;

        setEmail(props.value.email);
        setPhoneNumber(props.value.phoneNumber);
        setName(props.value.name);
    }, [props.value]);

    const isInvalid = !email || !name || !phoneNumber;
    const didChange =
        props.value?.name !== name ||
        props.value?.email !== email ||
        props.value?.phoneNumber !== phoneNumber;

    const handleUpdate = useCallback(async () => {
        if (isInvalid || !didChange)
            return;

        await props.onAction({ email, phoneNumber, name });
    }, [didChange, email, isInvalid, name, phoneNumber, props]);

    return (
        <Card className={styles.baseFormUser}>
            <HeaderApp title={props.title}>
                {props.children}
            </HeaderApp>
            <div className={styles.form}>
                <div className={styles.fields}>
                    <Field
                        label="Name"
                        placeholder="Jhon Doe"
                        onChange={setName}
                        value={name}
                        className={styles.field}
                    />

                    <Field
                        label="Email"
                        placeholder="jhonDoe@icloud.com"
                        onChange={setEmail}
                        value={email}
                        className={styles.field}
                    />

                    <FieldPhoneNumber
                        onChange={setPhoneNumber}
                        value={phoneNumber}
                        className={styles.field}
                    />
                </div>

                <Button
                    className={styles.button}
                    label={props.labelAction}
                    loading={isLoading}
                    disabled={isInvalid || !didChange}
                    onClick={handleUpdate} />
            </div>
        </Card>

    );
};
