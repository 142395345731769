import React from 'react';
import { Label } from '../../../utils/label';
import styles from './client-wallets.module.scss';
import { useAppSelector } from '../../../../hooks';
import { getSelectedClient } from '../../../../store/clientSlice';
import { WalletAddress } from '../../../../types/Client';
import { getCoinInfo } from '../../../../utils/constants';

export const ClientWallets = () => {
    const client = useAppSelector(getSelectedClient)!;

    const renderWallet = (wallet: WalletAddress, index: number) => {
        const coin = getCoinInfo(wallet.coin);

        return (
            <Label key={index} text={coin.label}>
                {wallet.address}
            </Label>
        );
    };

    return (
        <div className={styles.clientWallets}>
            <h3>Deposit Wallets</h3>
            {client.wallets.map(renderWallet)}
        </div>
    );
};
