import React, { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BasePartner, UpdatePartnerDto } from '../../types/Partner';
import { useNavigate, useParams } from 'react-router-dom';
import {
    deletePartner,
    generateSecret,
    getPartner,
    getRecentlyCreatedSecret,
    updatePartner
} from '../../store/partnerSlice';
import { FormPartner } from './form-partner';
import { Button } from '../utils/button';
import { openConfirmation } from '../../store/appSlice';
import { IconButton } from '../utils/icon-button';

export const EditPartner: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const newlyCreatedSecret = useAppSelector(getRecentlyCreatedSecret);
    const { id } = useParams();

    const partnerFromParam = useAppSelector(getPartner(id));
    const partnerNewlyCreated = useAppSelector(getPartner(newlyCreatedSecret?.id));

    const partner = partnerFromParam || partnerNewlyCreated;

    const handleUpdate = useCallback(async (dto: BasePartner) => {
        if (!partner)
            return;

        const payload: UpdatePartnerDto = {
            ...dto,
            id: partner.id
        };

        await dispatch(updatePartner(payload));
        navigate('/partners');
    }, [dispatch, navigate, partner]);

    const handleDelete = useCallback(async () => {
        if (!partner)
            return;

        dispatch(openConfirmation({
            title: 'Delete Partner',
            text: `Are you sure you want to delete ${partner.name}?`,
            onConfirm: async () => {
                await dispatch(deletePartner(partner.id));
                navigate('/partners');
            }
        }));
    }, [dispatch, navigate, partner]);


    const handleGenerateSecret = useCallback(() => {
        if (!partner)
            return;

        dispatch(openConfirmation({
            title: 'Sure to generate new secret',
            text: `Are you sure you want to generate a new secret for ${partner.name}? The old one will be invalidated.`,
            onConfirm: async () => {
                await dispatch(generateSecret(partner!.id));
            }
        }));
    }, [dispatch, partner]);

    if (!partner)
        return <span>Loading...</span>;

    return (
        <FormPartner
            title={`Edit ${partner.name}`}
            labelAction="Update"
            onAction={handleUpdate}
            value={partner}
            id={partner.id}
        >
            <IconButton icon="password" onClick={handleGenerateSecret} />

            <Button
                label="Delete"
                appearance="outline"
                onClick={handleDelete} />
        </FormPartner>
    );
};
