import { Dialog } from '../../utils/dialog';
import React, { useEffect, useState } from 'react';
import { SelectField, SelectFieldOptions } from '../../utils/select-field';
import { coinOptions, getCoinInfo } from '../../../utils/constants';
import { Field } from '../../utils/field';
import styles from './add-credit-annotation.module.scss';
import { Label } from '../../utils/label';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useSelector } from 'react-redux';
import { CoinEnum } from '../../../types/Amount';
import {
    closeAddCreditAnnotation,
    getAddCreditAnnotationIsOpened,
    getSelectedPartner
} from '../../../store/partnerSlice';
import { createCreditAnnotation, getPartnerBalance } from '../../../store/statementSlice';
import { CreateCreditAnnotation } from '../../../types/CreateCreditAnnotation';
import { add } from '../../../utils';
import { formatAmount } from '../../../utils/formatters';

type transactionType = 'deposit' | 'withdraw';
const transactionTypeOptions: SelectFieldOptions<transactionType> = [
    { value: 'deposit', label: 'Deposit' },
    { value: 'withdraw', label: 'Withdraw' },
];

export const AddCreditAnnotation = () => {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getAddCreditAnnotationIsOpened);
    const partner = useSelector(getSelectedPartner);
    const balance = useAppSelector(getPartnerBalance(partner?.id));

    const [coin, setCoin] = useState<CoinEnum>(CoinEnum.usdt);
    const [amount, setAmount] = useState<number>();
    const [motive, setMotive] = useState<string>();
    const [transactionType, setTransactionType] = useState<transactionType>('deposit');

    useEffect(() => {
        setCoin(CoinEnum.usdt);
        setAmount(undefined);
        setMotive(undefined);
        setTransactionType('deposit');
    }, [isOpen]);

    if (!partner) return null;

    const coinInfo = getCoinInfo(coin);

    const multiplyFactor = transactionType === 'withdraw' ? -1 : 1;
    const executeChangeBalance = () => {
        const creditAnnotation: CreateCreditAnnotation = {
            partnerId: partner.id,
            amount: {
                coin: coin,
                value: amount! * multiplyFactor,
            },
            motive: motive!,
        };

        dispatch(createCreditAnnotation(creditAnnotation));
    };

    const value = add(balance.balances[coin], (amount || 0) * multiplyFactor);
    const disabled = !coin || !amount || !motive || motive.length <= 10;

    const styleBalanceAfter = value === 0 ? '' : value > 0 ? styles.positive : styles.negative;

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => dispatch(closeAddCreditAnnotation())}
            title="Create Transaction"
            subtitle={`for ${partner.name} `}
            action="Confirm"
            onAction={executeChangeBalance}
            actionDisabled={disabled}
        >
            <div className={styles.addCreditAnnotation}>
                <SelectField
                    options={transactionTypeOptions}
                    onChange={setTransactionType}
                    value={transactionType}
                    label="Transaction Type"
                />
                <SelectField
                    options={coinOptions.map(x => ({ label: x.label, value: x.id }))}
                    onChange={setCoin}
                    value={coin}
                    label="Coin"
                />
                <Field
                    label="Amount"
                    placeholder={coinInfo.placeholder}
                    onChange={amt => setAmount(parseFloat(amt))}
                    suffix={<span className={styles.amountLabel}>{coinInfo.symbol}</span>}
                    className={styles.amountField}
                />
                <Field
                    label="Motive"
                    placeholder="Why are you doing this operation?"
                    value={motive}
                    onChange={setMotive}
                />
                <Label
                    text="Balance after transaction" content={formatAmount({ coin, value })}
                    className={styleBalanceAfter} />
            </div>
        </Dialog>
    );
};
