import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteOperator, getOperator, updateOperator } from '../../../store/operatorSlice';
import { BaseFormUser } from '../../app/base-form-user';
import { Button } from '../../utils/button';
import { BaseUser, UpdateUserDto } from '../../../types/User';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';

export const UpdateOperator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const operator = useSelector(getOperator(id));

    const goToOperators = () => navigate('/operators');

    const handleDelete = useCallback(async () => {
        if (!operator)
            return;

        await dispatch(deleteOperator(operator!.id));
        goToOperators();
    }, [dispatch, goToOperators, operator]);

    const handleUpdate = useCallback(async (dto: BaseUser) => {
        if (!operator)
            return;

        const payload: UpdateUserDto = {
            ...dto,
            id: operator!.id
        };

        await dispatch(updateOperator(payload));
        goToOperators();
    }, [dispatch, goToOperators, operator]);

    if (!operator)
        return <div>loading...</div>;

    return (
        <BaseFormUser
            title={`Edit ${operator.name.split(' ')[0]}`}
            labelAction="Update"
            onAction={handleUpdate}
            value={operator}
        >
            <Button
                label="Delete"
                appearance="outline"
                onClick={handleDelete} />
        </BaseFormUser>
    );
};

