import { Withdraw, WithdrawStatusEnum } from '../types/Withdraw';
import { delay, upsertOnList } from '../utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Columns, downloadCsv } from '../utils/report';
import { documentFormatter, formatValue } from '../utils/formatters';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { Websocket } from '../services/Websocket';
import WithdrawService from '../services/WithdrawService';
import { showAlert } from './notificationSlice';

const WITHDRAW_KEY = 'withdraws';
const WITHDRAW_LAST_UPDATED_DATE_KEY = 'withdraws_last_updated';

interface WithdrawState {
    withdraws: Withdraw[];
    selectedWithdrawId: string | null;
    markAsCompletedIsOpen: boolean;
    detailIsOpen: boolean;
    withdrawsLoading: string[];
}

const initialState: WithdrawState = {
    withdraws: [],
    selectedWithdrawId: null,
    markAsCompletedIsOpen: false,
    detailIsOpen: false,
    withdrawsLoading: []
};

const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        openWithdrawDetail: (state, action: PayloadAction<Withdraw | null>) => {
            state.selectedWithdrawId = action.payload?.id || null;
            state.detailIsOpen = true;
        },
        openMarkAsCompleted: (state, action: PayloadAction<Withdraw | null>) => {
            state.detailIsOpen = false;
            state.markAsCompletedIsOpen = true;
            state.selectedWithdrawId = action.payload?.id || null;
        },
        closePopups: (state) => {
            state.selectedWithdrawId = null;
            state.markAsCompletedIsOpen = false;
            state.detailIsOpen = false;
        },
        updateWithdrawList: (state, action: PayloadAction<Withdraw[]>) => {
            state.withdraws = action.payload;
        },
        upsertWithdraw: (state, action: PayloadAction<Withdraw>) => {
            state.withdraws = upsertOnList(state.withdraws, action.payload);
        },
        setIsLoading: (state, action: PayloadAction<string>) => {
            state.withdrawsLoading = [...state.withdrawsLoading, action.payload];
        },
        removeIsLoading: (state, action: PayloadAction<string>) => {
            state.withdrawsLoading = state.withdrawsLoading.filter(x => x !== action.payload);
        }
    }
});

export const {
    openWithdrawDetail,
    openMarkAsCompleted,
    closePopups,
    updateWithdrawList,
    upsertWithdraw,
} = withdrawSlice.actions;

const { setIsLoading, removeIsLoading } = withdrawSlice.actions;

export const updateWithdrawStatus = (
    withdraw: Withdraw,
    status: WithdrawStatusEnum,
    txId?: string
): AppThunk => dispatch => {
    dispatch(setIsLoading(withdraw.id));

    WithdrawService.updateStatus(withdraw.id, status, txId)
        .then(() => {
            dispatch(showAlert('Withdraw updated'));
            dispatch(removeIsLoading(withdraw.id));

            if (status === WithdrawStatusEnum.done)
                dispatch(closePopups());
        })
        .catch(err => {
            dispatch(removeIsLoading(withdraw.id));
            throw err;
        });
};

export const downloadWithdrawsList = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().withdraw.withdraws;
    const partners = getState().partner.partners;

    const getPartnerName = (partnerId: string): string => {
        const partner = partners.find(x => x.id === partnerId);
        return partner ? partner.name : 'Unknown';
    };

    const columns: Columns<Withdraw> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Status', getValue: x => x.status },
        { title: 'Client Id', getValue: x => x.client.id },
        { title: 'Client Document', getValue: x => documentFormatter(x.client.document) },
        { title: 'Partner Name', getValue: x => getPartnerName(x.client.partnerId) },
        { title: 'Amount to Receive', getValue: x => formatValue(x.coin, x.amountToReceive) },
        { title: 'Amount Requested', getValue: x => formatValue(x.coin, x.amountRequested) },
        { title: 'Fee', getValue: x => formatValue(x.coin, x.fee) },
        { title: 'Wallet', getValue: x => x.wallet },
        { title: 'External Identifier', getValue: x => x.externalIdentifier },
        { title: 'External Reference', getValue: x => x.externalReference },
        { title: 'Operator', getValue: x => x.operator?.name || 'Unknown' },
        { title: 'Updated At', getValue: x => x.updatedAt.toLocaleString() },
        { title: 'Created At', getValue: x => x.createdAt.toLocaleString() },
    ];

    downloadCsv(lst, columns, 'withdraws.csv');
};

export const cleanWithdrawStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(updateWithdrawList([]));
    removeOnLocalStorage(WITHDRAW_KEY);
    removeOnLocalStorage(WITHDRAW_LAST_UPDATED_DATE_KEY);
};

export const setupWithdrawState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Withdraw>('update-withdraw', (withdraw: Withdraw) => {
        dispatch(upsertWithdraw(withdraw));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(WITHDRAW_LAST_UPDATED_DATE_KEY);
    Websocket.emitOnConnection('subscribe-withdraw', payload);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().withdraw.withdraws;
    saveOnLocalStorage(WITHDRAW_KEY, lst);
    saveLastUpdatedDate(WITHDRAW_LAST_UPDATED_DATE_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Withdraw[]>(WITHDRAW_KEY);

    if (!lst)
        return;

    dispatch(updateWithdrawList(lst));
};

export const getWithdrawIsLoading = (id: string) => (state: RootState): boolean =>
    state.withdraw.withdrawsLoading.includes(id);
export const getSelectedWithdraw = (state: RootState): Withdraw | null => {
    return state.withdraw.withdraws.find(x => x.id === state.withdraw.selectedWithdrawId) || null;
};
export const getDetailIsOpen = (state: RootState): boolean => state.withdraw.detailIsOpen;
export const getPendingWithdraws = (state: RootState): Withdraw[] => state.withdraw.withdraws.filter(x => x.status !== WithdrawStatusEnum.done);
export const getMarkAsCompleteIsOpen = (state: RootState): boolean => state.withdraw.markAsCompletedIsOpen;

export default withdrawSlice.reducer;


