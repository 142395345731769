import React, { FunctionComponent } from 'react';
import { Client } from '../../../../types/Client';
import { TabOption, Tabs } from '../../../utils/tabs';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Card } from '../../../utils/card';
import styles from './client.module.scss';
import { Statement } from '../statement';
import { ClientWallets } from '../client-wallets';
import { useSelector } from 'react-redux';
import { RoleEnum } from '../../../../types/User';
import { getProfileIsOpen } from '../../../../store/appSlice';

interface Props {
    client: Client;
}

export interface HasActiveProp {
    active: boolean;
}

type RouteOption = TabOption & {
    Component: FunctionComponent,
    path: string,
    Suffix?: FunctionComponent<HasActiveProp>,
    permission?: RoleEnum
}
export const ClientDetails: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();
    const profileIsOpened = useSelector(getProfileIsOpen);

    const openRoute = (subRoute: string) => () => navigate(`${subRoute}`);
    const isActive = (subRoute: string) => window.location.pathname.includes(subRoute);

    const getBaseRoute = (subRoute: string) => ({
        onClick: openRoute(subRoute),
        active: isActive(subRoute),
        path: `${subRoute}`,
    });

    const options: RouteOption[] = [
        {
            ...getBaseRoute('statement'),
            label: 'Statement',
            Component: Statement,
        },
        {
            ...getBaseRoute('walletAndKeys'),
            label: 'Wallet | Keys',
            Component: ClientWallets,
        },
    ];

    return (
        <Card
            className={`${styles.details} ${profileIsOpened ? styles.isProfileOpened : ''}`}
            classNameBody={styles.detailsBody}>
            <Tabs options={options} />
            <Routes>
                {options.map((item, key) =>
                    <Route key={key} path={item.path} element={<item.Component />} />,
                )}

                <Route path={'*'} element={<Navigate to={options[0].path} />} />
            </Routes>
        </Card>
    );
};
