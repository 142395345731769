import React, { FunctionComponent } from 'react';
import { getSelectedTransaction, TransactionDetail as ITransactionDetail } from '../../../../../store/statementSlice';
import { WithData } from '../../../../../types';
import { Deposit } from '../../../../../types/Deposit';
import { Withdraw } from '../../../../../types/Withdraw';
import { Label } from '../../../../utils/label';
import styles from './transaction-detail.module.scss';
import { useSelector } from 'react-redux';
import { formatAmount, formatValue, labelWithdrawStatus } from '../../../../../utils/formatters';
import { FundAccountTransaction, TransactionTypeEnum } from '../../../../../types/StatementTransaction';
import { CreditAnnotation } from '../../../../../types/Balance';

interface LabelItem {
    title: string;
    value: string;
}

interface Props {
    title: string;
    labels: LabelItem[];
    children?: React.ReactNode;
}

const BasicTransactionDetail: FunctionComponent<Props> = (props) => {
    const renderLabel = (item: LabelItem, index: number) =>
        <Label key={index} text={item.title} content={item.value} />;

    return (
        <div className={styles.detailBody}>
            <h2 className={styles.title}>{props.title}</h2>
            <div className={styles.info}>
                {props.labels.map(renderLabel)}
            </div>
            {props.children}
        </div>
    );
};

const WithdrawDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Withdraw;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'External Identifier', value: transaction.externalIdentifier || '--' },
        { title: 'Amount Requested', value: formatValue(transaction.coin, transaction.amountRequested) },
        { title: 'Amount to Receive', value: formatValue(transaction.coin, transaction.amountToReceive) },
        { title: 'Fee', value: formatValue(transaction.coin, transaction.fee) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
        { title: 'Wallet', value: transaction.wallet },
        { title: 'Status', value: labelWithdrawStatus(transaction) },
    ];

    return <BasicTransactionDetail title="Withdraw" labels={labels} />;
};

const DepositDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as Deposit;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title="Deposit" labels={labels} />;
};

const CreditAnnotationDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as CreditAnnotation;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        { title: 'Amount', value: formatAmount(transaction.amount) },
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title="Credit Annotation" labels={labels} />;
};

const FundAccountDetail: FunctionComponent<WithData<ITransactionDetail>> = ({ data }) => {
    const transaction = data as FundAccountTransaction;
    const labels: LabelItem[] = [
        { title: 'Transaction Id', value: transaction.id },
        ...transaction.amounts.map((amount, index) => ({
            title: amount.value > 0 ? `Fund` : `UnFund`,
            value: formatAmount(amount),
        })),
        { title: 'Created at', value: transaction.createdAt.toLocaleString() },
    ];

    return <BasicTransactionDetail title="Fund Account" labels={labels} />;
};

const detailOptions = [
    { type: TransactionTypeEnum.withdraw, Component: WithdrawDetail },
    { type: TransactionTypeEnum.deposit, Component: DepositDetail },
    { type: TransactionTypeEnum.creditAnnotation, Component: CreditAnnotationDetail },
    { type: TransactionTypeEnum.fundAccount, Component: FundAccountDetail },
];

export const TransactionDetail = () => {
    const selectedTransaction = useSelector(getSelectedTransaction);

    if (!selectedTransaction)
        return <div>Oddly, no transaction selected. Please call the developer</div>;

    const { Component } = detailOptions.find(x => x.type === selectedTransaction.type)!;
    return <div className={styles.transactionDetail}>
        <Component data={selectedTransaction.transaction} />
    </div>;
};
