import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styles from './statement.module.scss';
import ArrowUp from '../../../../assets/images/icons/arrow-upward.svg';
import ArrowDown from '../../../../assets/images/icons/arrow-downward.svg';
import ArrowLeft from '../../../../assets/images/icons/arrow-left.svg';
import ArrowSwap from '../../../../assets/images/icons/arrow-swap.svg';
import { Amount, CoinEnum } from '../../../../types/Amount';
import { TransactionDetail } from './transaction-detail';
import { getSelectedClient } from '../../../../store/clientSlice';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../../utils/formatters';
import { StatementTransaction, TransactionTypeEnum } from '../../../../types/StatementTransaction';
import {
    getSelectedTransaction,
    getStatement,
    selectFirstTransaction,
    updateSelectedTransaction
} from '../../../../store/statementSlice';
import { useAppDispatch } from '../../../../hooks';

interface BasicTransactionProps {
    id: string;
    icon: string;
    title: string;
    coin: CoinEnum;
    amount: number;
    active: boolean;
    secondAmount?: Amount;
}

const BasicTransaction: FunctionComponent<BasicTransactionProps> = props => {
    const dispatch = useAppDispatch();
    const hasSecondAmountStyle = !!props.secondAmount ? styles.hasSecondAmount : '';
    const activeStyle = props.active ? styles.active : '';
    const handleClick = () => dispatch(updateSelectedTransaction(props.id));

    return (
        <div className={`${styles.transactionItem} ${activeStyle}`} onClick={handleClick}>
            <div className={styles.icon}>
                <img src={props.icon} alt="arrow" />
            </div>
            <div className={`${styles.info} ${hasSecondAmountStyle}`}>
                <span>{props.title}</span>
                <span>{formatAmount({ value: props.amount, coin: props.coin })}</span>
                {props.secondAmount && <span>{formatAmount(props.secondAmount)}</span>}
            </div>
        </div>
    );
};

export const Statement = () => {
    const dispatch = useAppDispatch();
    const client = useSelector(getSelectedClient);
    const statement = useSelector(getStatement(client));
    const selectedTransaction = useSelector(getSelectedTransaction);
    const [detailIsOpened, setDetailIsOpened] = useState(false);

    useEffect(() => {
        if (!client)
            return;

        dispatch(selectFirstTransaction(client.id));
    }, [client, dispatch]);

    const getBasicTransactionProps = (item: StatementTransaction): {
        active: boolean;
        id: string;
    } => {
        const active = selectedTransaction?.transaction.id === item.id;
        return { active, id: item.id };
    };

    const renderTransactions = (item: StatementTransaction) => {
        const basicProps = getBasicTransactionProps(item);

        if (item.type === TransactionTypeEnum.deposit)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={ArrowDown}
                    title="Deposit"
                    amount={item.amount}
                    coin={item.coin} />
            );

        if (item.type === TransactionTypeEnum.withdraw)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={ArrowUp}
                    title="Withdraw"
                    amount={item.amountRequested}
                    coin={item.coin} />
            );

        if (item.type === TransactionTypeEnum.creditAnnotation)
            return (
                <BasicTransaction
                    key={item.id}
                    {...basicProps}
                    icon={ArrowDown}
                    title="Credit Annotation"
                    amount={item.amount}
                    coin={item.coin} />
            );

        if (item.type === TransactionTypeEnum.fundAccount)
            return (
                <>
                    {item.amounts.map((amount, index) => (
                        <BasicTransaction
                            key={index}
                            id={`${item.id}-${index}`}
                            active={basicProps.active}
                            icon={ArrowSwap}
                            title={amount.value > 0 ? 'Funding' : 'Unfund'}
                            amount={amount.value}
                            coin={amount.coin} />
                    ))}
                </>
            );

        return <div>This is an error, please show to the developer</div>;
    };

    const renderComponent = (child: ReactElement) => (
        <div className={styles.clientStatement}>
            {child}
        </div>
    );

    if (!statement.length)
        return renderComponent(<span className={`${styles.content} ${styles.notFound}`}>No transactions found for this client</span>);

    return renderComponent(
        <div className={`${styles.content} ${detailIsOpened ? styles.detailIsOpened : ''}`}>
            <div className={styles.lstStatement}>
                {statement.map(renderTransactions)}
            </div>
            <div className={styles.detailWrapper}>
                <div className={styles.backButton} onClick={() => setDetailIsOpened(false)}>
                    <img src={ArrowLeft} alt="Back Button" />
                </div>
                <TransactionDetail />
            </div>
        </div>,
    );
};
