import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAddressesWithClients } from '../../../store/tronWalletsSlice';
import { Dialog } from '../../utils/dialog';
import { Field } from '../../utils/field';
import { IconButton } from '../../utils/icon-button';
import styles from './search-tron-address.module.scss';
import { TronAddressWithClient } from '../../../types/TronAddress';
import SearchIcon from '../../../assets/images/icons/search.svg';
import { PropsSearch } from '../../../types';

export const SearchTronAddress: FunctionComponent<PropsSearch> = (props) => {
    const {
        isOpen,
        onChosenAddress,
        onCancel,
    } = props;

    const sourceAddress = 'sourceAddress' in props ? props.sourceAddress : undefined;
    const targetAddress = 'targetAddress' in props ? props.targetAddress : undefined;

    const [searchTerm, setSearchTerm] = useState('');
    const addresses = useSelector(getAddressesWithClients);

    const renderAddress = (item: TronAddressWithClient) => (
        <tr key={item.address} onClick={() => onChosenAddress(item.address)}>
            <td data-title="Name">{item.client?.document}</td>
            <td data-title="Address">{item.address}</td>
        </tr>
    );

    const shouldShowAddress = (item: TronAddressWithClient): boolean => {
        if (sourceAddress === item.address || targetAddress === item.address)
            return false;

        if (!searchTerm)
            return true;

        const matchAddress = item.address.toLowerCase().includes(searchTerm.toLowerCase());

        if (!item.client)
            return matchAddress;

        const cli = item.client;

        const onlyNumbersSearchTerm = searchTerm.replace(/\D/g, '');
        const onlyNumbersDocument = cli.document?.replace(/\D/g, '') || '';
        const matchDocument = !!onlyNumbersSearchTerm && onlyNumbersDocument.includes(onlyNumbersSearchTerm);

        return matchAddress || matchDocument;
    };

    return (
        <Dialog
            isOpen={isOpen}
            title="Send to"
            leading={<IconButton icon="arrow-left" onClick={onCancel} />}
            className={styles.searchEthereumAddress}>
            <Field
                className={styles.field}
                label="Search"
                placeholder="142.142.123-23"
                value={searchTerm}
                onChange={setSearchTerm}
                suffix={<img src={SearchIcon} alt="Search Icon" className={styles.iconSearch} />}
            />

            <div className={styles.tableAddress}>
                <table>
                    <tbody>
                    {addresses
                        .filter(shouldShowAddress)
                        .map(renderAddress)}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};
