import React, { FunctionComponent } from 'react';
import styles from './app-bar.module.scss';
import { getLoggedUser, logoff, openChangePasswordDialog } from '../../../store/authSlice';
import VpnKey from '../../../assets/images/icons/vpn-key.svg';
import Logout from '../../../assets/images/icons/logout.svg';
import Person from '../../../assets/images/icons/person.svg';
import MenuIcon from '../../../assets/images/icons/menu.svg';
import { toggleMenuIsOpen } from '../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '../../utils/avatar';
import { DropdownItem, Dropdowns } from '../../utils/dropdowns';

const UserAvatar: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loggedUser = useAppSelector(getLoggedUser)!;

    const mySelfItem: DropdownItem = {
        icon: Person,
        onClick: () => navigate('/my-self'),
        label: 'My Self',
    };

    const changePasswordItem: DropdownItem = {
        icon: VpnKey,
        onClick: () => dispatch(openChangePasswordDialog()),
        label: 'Edit Password',
    };

    const logoffItem: DropdownItem = {
        icon: Logout,
        onClick: () => dispatch(logoff()),
        label: 'Exit',
    };

    return (
        <Dropdowns options={[mySelfItem, changePasswordItem, logoffItem]}>
            <Avatar primary={true} user={loggedUser} />
        </Dropdowns>
    );
};


export const AppBar = () => {
    const dispatch = useAppDispatch();
    const toggleOpened = () => dispatch(toggleMenuIsOpen());

    return (
        <div className={styles.appBar}>
            <div className={styles.menuIcon} onClick={toggleOpened}>
                <img src={MenuIcon} alt="Menu icon" />
            </div>
            <div />
            <UserAvatar />
        </div>
    );
};
