import { WithdrawStatusEnum } from '../types/Withdraw';
import { getUrlGenerator } from '../utils';
import { Api } from './Api';

const baseUrl = process.env.REACT_APP_WITHDRAW_API!;

const url = getUrlGenerator(baseUrl);

export default class WithdrawService {
    public static updateStatus = (id: string, status: WithdrawStatusEnum, txId?: string): Promise<void> =>
        Api.patch(url(id), { status, externalIdentifier: txId });
}
