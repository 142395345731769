import { CoinEnum } from './Amount';
import { BasicClient } from './BasicClient';
import { User } from './User';

export enum WithdrawStatusEnum {
    awaiting = 'awaiting',
    pending = 'pending',
    doing = 'doing',
    done = 'done',
}

export interface WithdrawHistoryEntry {
    status: WithdrawStatusEnum;
    operator?: User;
    createdAt: Date;
}

export interface Withdraw {
    id: string;
    client: BasicClient;
    coin: CoinEnum;
    amountToReceive: number;
    amountRequested: number;
    fee: number;
    externalIdentifier?: string;
    wallet: string;
    status: WithdrawStatusEnum;
    history: WithdrawHistoryEntry[];
    operator?: User;
    externalReference?: string;
    createdAt: Date;
    updatedAt: Date;
}
