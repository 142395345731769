import React, { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { downloadPartnersList, getPartners } from '../../store/partnerSlice';
import { Card } from '../utils/card';
import styles from './partners.module.scss';
import { HeaderApp } from '../utils/header';
import { IconButton } from '../utils/icon-button';
import { WithData } from '../../types';
import { Partner } from '../../types/Partner';
import { useNavigate } from 'react-router-dom';
import { FloatingActionButton } from '../utils/floating-action-button';
import Add from '../../assets/images/icons/add.svg';

export const Partners: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const partners = useAppSelector(getPartners);

    const newPartner = useCallback(() => {
        navigate('/partners/add');
    }, [navigate]);

    return (
        <Card className={styles.partners}>
            <HeaderApp title="Partners">
                <IconButton icon="download" onClick={() => dispatch(downloadPartnersList())} />
            </HeaderApp>

            <div className={styles.tablePartners}>
                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Webhook Url</th>
                    </tr>
                    </thead>
                    <tbody>
                    {partners.map((partner, key) => (
                        <PartnerItem data={partner} key={key} />
                    ))}
                    </tbody>
                </table>
            </div>

            <FloatingActionButton onClick={newPartner} icon={Add} />
        </Card>
    );
};

export const PartnerItem: FunctionComponent<WithData<Partner>> = ({ data }) => {
    const navigate = useNavigate();

    return (
        <tr onClick={() => navigate(`/partners/${data.id}`)}>
            <td data-title="Id">{data.id}</td>
            <td data-title="Name">{data.name}</td>
            <td data-title="Webhook Url">{data.webhookUrl}</td>
        </tr>
    );
};
