import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { CreateCreditAnnotation } from '../types/CreateCreditAnnotation';

const baseUrl = process.env.REACT_APP_ACCOUNTING_API;

const generateUrl = getUrlGenerator(baseUrl);

export default class AccountingService {
    public static createCreditAnnotation = (obj: CreateCreditAnnotation): Promise<void> =>
        Api.post(generateUrl('credit-annotation'), obj);
}
