export enum RoleEnum {
    admin = 1,
    operator = 2
}

export interface BaseUser {
    name: string;
    phoneNumber: string;
    email: string;
}

export interface User extends BaseUser {
    id: string;
    role: RoleEnum;
}

export interface LoginRequest {
    email: string;
    password: string;
    twoFactorAuthCode: number;
}

export interface LoginResponse {
    token: string;
    passwordMustBeChanged: boolean;
}

export type CreateUserDto = BaseUser;
export type UpdateMySelfDto = BaseUser;

export interface UpdateUserDto extends CreateUserDto {
    id: string;
}
