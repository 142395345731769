import { CopyButton } from '../../utils/copy-button';
import { IconButton } from '../../utils/icon-button';
import React, { FunctionComponent } from 'react';
import styles from './pending-withdraws.module.scss';
import { Withdraw } from '../../../types/Withdraw';
import { getPendingWithdraws, openWithdrawDetail } from '../../../store/withdrawSlice';
import { WithData } from '../../../types';
import { ActionWithdraw } from '../ActionWithdraw';
import { WithdrawDetail } from '../withdraw-detail';
import { Card } from '../../utils/card';
import { MarkAsCompleted } from '../mark-as-completed';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getPartner } from '../../../store/partnerSlice';
import { documentFormatter, formatValue, labelWithdrawStatus } from '../../../utils/formatters';

const PendingWithdrawItem: FunctionComponent<WithData<Withdraw>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const { client, wallet, amountToReceive, coin } = data;

    const partner = useAppSelector(getPartner(client.partnerId));

    return (
        <tr>
            <td data-title="Document">{documentFormatter(client.document)}</td>
            <td data-title="Partner">{partner?.name}</td>
            <td data-title="Amount">
                <div className={styles.wrapperTd}>
                    {formatValue(coin, amountToReceive)}
                    <CopyButton value={formatValue(coin, amountToReceive)} label="Amount" />
                </div>
            </td>
            <td data-title="Status">{labelWithdrawStatus(data)}</td>
            <td data-title="Key/Wallet">
                <div className={styles.wrapperTd}>
                    {wallet}
                    <CopyButton value="Crypto address" label={wallet} />
                </div>
            </td>
            <td>
                <ActionWithdraw withdraw={data} />
            </td>
            <td>
                <IconButton icon="eye" onClick={() => dispatch(openWithdrawDetail(data))} />
            </td>
        </tr>
    );
};

export const PendingWithdraws = () => {
    const lst = useAppSelector(getPendingWithdraws);

    if (!lst.length)
        return null;

    return (
        <Card title="Pending Withdraws" className={styles.pendingWithdraws}>
            <div className={styles.withdraws}>
                <table>
                    <thead>
                    <tr>
                        <th>Client</th>
                        <th>Partner</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Key/Wallet</th>
                    </tr>
                    </thead>
                    <tbody>
                    {lst.map((x, key) => (
                        <PendingWithdrawItem data={x} key={key} />
                    ))}
                    </tbody>
                </table>
                <WithdrawDetail />
                <MarkAsCompleted />
            </div>
        </Card>
    );
};
