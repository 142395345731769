import React, { CSSProperties, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styles from './avatar.module.scss';
import { User } from '../../../types/User';
import PersonIcon from '../../../assets/images/icons/person.svg';

type sizeOptionsType = 'small' | 'normal' | 'big';

interface Props {
    user?: User;
    onClick?: Function;
    size?: sizeOptionsType;
    primary?: boolean;
    className?: string;
}

const sizeOptions = new Map<sizeOptionsType, string>();
sizeOptions.set('small', styles.small);
sizeOptions.set('normal', styles.normal);
sizeOptions.set('big', styles.big);

const colorOptions = [
    '#cb1804',
    '#0f0220',
    '#920005',
    '#623a06'
];

export const Avatar: FunctionComponent<Props> = (props) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        const randomColor = colorOptions[Math.floor(Math.random() * colorOptions.length)];
        setColor(randomColor);
    }, []);

    const style = sizeOptions.get(props.size || 'normal')!;

    const colorStyle: CSSProperties = props.primary ? {} : { backgroundColor: color };
    return (
        <div
            style={colorStyle}
            className={`${styles.avatar} ${style} ${props.className}`}
            onClick={() => props.onClick && props.onClick()}>
            <div>{getContent(props.user)}</div>
        </div>
    );
};

const getContent = (user?: User): string | ReactElement => {
    if (!user)
        return <img src={PersonIcon} alt="person" />;

    const lstNames = user.name.split(' ');
    const first = lstNames[0];
    const last = lstNames[lstNames.length - 1];
    return first[0] + last[0];
};
