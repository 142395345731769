import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getPartners, openAddCreditAnnotation } from '../../../store/partnerSlice';
import { Partner } from '../../../types/Partner';
import { WithData } from '../../../types';
import { getPartnerBalance } from '../../../store/statementSlice';
import { Card } from '../../utils/card';
import { Label } from '../../utils/label';
import styles from './partner-balances.module.scss';
import { Amount, CoinEnum } from '../../../types/Amount';
import { Button } from '../../utils/button';

export const PartnerBalances: FunctionComponent = () => {
    const partners = useAppSelector(getPartners);

    return (
        <>
            {partners.map(partner => (<PartnerBalance key={partner.id} data={partner} />))}
        </>
    );
};

export const PartnerBalance: FunctionComponent<WithData<Partner>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const balance = useAppSelector(getPartnerBalance(data.id));

    const { btc, trx, eth, usdt, usdc } = balance.balances;

    const coins: Amount[] = [
        { coin: CoinEnum.btc, value: btc },
        { coin: CoinEnum.trx, value: trx },
        { coin: CoinEnum.eth, value: eth },
        { coin: CoinEnum.usdt, value: usdt },
        { coin: CoinEnum.usdc, value: usdc }
    ];

    const handleAddBalance = useCallback(() => {
        dispatch(openAddCreditAnnotation(data));
    }, [data, dispatch]);

    return (
        <Card title={data.name} classNameBody={styles.partnerBalance}>
            {coins.map((item, key) => (
                <Label text={item.coin} content={item.value?.toString()} key={key} />
            ))}

            <div className={styles.actions}>
                <Button label="Add Balance" onClick={handleAddBalance} />
            </div>
        </Card>
    );
};
