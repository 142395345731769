import { delay, generateUuid, upsertOnList, upsertOnListCustom } from '../utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatementTransaction, TransactionTypeEnum } from '../types/StatementTransaction';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveCustomLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { Websocket } from '../services/Websocket';
import { Balance, CreditAnnotation, RequestFundAccount } from '../types/Balance';
import { Deposit } from '../types/Deposit';
import { Withdraw } from '../types/Withdraw';
import { Client } from '../types/Client';
import AccountingService from '../services/AccountingService';
import { CreateCreditAnnotation } from '../types/CreateCreditAnnotation';
import { closeAddCreditAnnotation } from './partnerSlice';
import { showAlert } from './notificationSlice';

const STATEMENT_KEY = 'statements';
const STATEMENT_LAST_UPDATED_DATE_KEY = 'statements_last_updated';

const BALANCE_KEY = 'balances';
const BALANCE_LAST_UPDATED_DATE_KEY = 'balances_last_updated';

const CREDIT_ANNOTATIONS_KEY = 'credit_annotations';
const CREDIT_ANNOTATIONS_LAST_UPDATED_DATE_KEY = 'credit_annotations_last_updated';

const FUND_ACCOUNT_KEY = 'fund_account';
const FUND_ACCOUNT_LAST_UPDATED_DATE_KEY = 'fund_account_last_updated';

export type TransactionDetail = Withdraw | Deposit | CreditAnnotation | RequestFundAccount;

type SelectedTransaction = null | {
    type: TransactionTypeEnum;
    transaction: TransactionDetail;
};

interface StatementState {
    statements: StatementTransaction[];
    lstBalance: Balance[];
    lstCreditAnnotation: CreditAnnotation[];
    lstFundAccount: RequestFundAccount[];
    selectedTransactionId: string | null;
}

const initialState: StatementState = {
    statements: [],
    lstBalance: [],
    lstCreditAnnotation: [],
    lstFundAccount: [],
    selectedTransactionId: null
};

const statementSlice = createSlice({
    name: 'statement',
    initialState,
    reducers: {
        updateStatementList: (state, action: PayloadAction<StatementTransaction[]>) => {
            state.statements = action.payload;
        },
        upsertStatement: (state, action: PayloadAction<StatementTransaction>) => {
            state.statements = upsertOnList(state.statements, action.payload);
        },
        updateBalanceList: (state, action: PayloadAction<Balance[]>) => {
            state.lstBalance = action.payload;
        },
        upsertBalance: (state, action: PayloadAction<Balance>) => {
            state.lstBalance = upsertOnListCustom(state.lstBalance, action.payload, x => x.clientId);
        },
        updateCreditAnnotationList: (state, action: PayloadAction<CreditAnnotation[]>) => {
            state.lstCreditAnnotation = action.payload;
        },
        upsertCreditAnnotation: (state, action: PayloadAction<CreditAnnotation>) => {
            state.lstCreditAnnotation = upsertOnList(state.lstCreditAnnotation, action.payload);
        },
        updateFundAccountList: (state, action: PayloadAction<RequestFundAccount[]>) => {
            state.lstFundAccount = action.payload;
        },
        upsertFundAccount: (state, action: PayloadAction<RequestFundAccount>) => {
            state.lstFundAccount = upsertOnList(state.lstFundAccount, action.payload);
        },
        updateSelectedTransaction: (state, action: PayloadAction<string>) => {
            state.selectedTransactionId = action.payload;
        },
        selectFirstTransaction: (state, action: PayloadAction<string>) => {
            const clientId = action.payload;

            state.selectedTransactionId = state.statements.find(x => x.clientId === clientId)?.id || null;
        }
    }
});

export const {
    updateStatementList,
    upsertStatement,
    updateBalanceList,
    upsertBalance,
    updateCreditAnnotationList,
    upsertCreditAnnotation,
    updateFundAccountList,
    upsertFundAccount,
    updateSelectedTransaction,
    selectFirstTransaction
} = statementSlice.actions;


export const createCreditAnnotation = (obj: CreateCreditAnnotation): AppThunk => dispatch => {
    AccountingService.createCreditAnnotation(obj).then(x => {
        dispatch(closeAddCreditAnnotation());
        dispatch(showAlert('Annotation created successfully'));
    });
};

export const cleanStatementStateAndStorage = (): AppThunk => async (dispatch, getState) => {
    dispatch(updateStatementList([]));
    removeOnLocalStorage(STATEMENT_KEY);
    removeOnLocalStorage(STATEMENT_LAST_UPDATED_DATE_KEY);
};

export const setupStatementState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<StatementTransaction>('new-statement-transaction', (data: StatementTransaction) => {
        dispatch(upsertStatement(data));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<CreditAnnotation>('new-credit-annotation', (data: CreditAnnotation) => {
        dispatch(upsertCreditAnnotation(data));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<RequestFundAccount>('new-fund-account', (data: RequestFundAccount) => {
        dispatch(upsertFundAccount(data));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<Balance>('update-balance', (data: Balance) => {
        dispatch(upsertBalance(data));
        return updateOnStorage(getState);
    });

    const lastUpdateBalanceDate = retrieveLastUpdatedDateOrDefault(BALANCE_LAST_UPDATED_DATE_KEY);
    const lastUpdateStatementDate = retrieveLastUpdatedDateOrDefault(STATEMENT_LAST_UPDATED_DATE_KEY);
    const lastUpdateCreditAnnotationDate = retrieveLastUpdatedDateOrDefault(CREDIT_ANNOTATIONS_LAST_UPDATED_DATE_KEY);
    const lastUpdateFundAccountDate = retrieveLastUpdatedDateOrDefault(FUND_ACCOUNT_LAST_UPDATED_DATE_KEY);

    Websocket.emitOnConnection('subscribe-balance', lastUpdateBalanceDate);
    Websocket.emitOnConnection('subscribe-statement', lastUpdateStatementDate);
    Websocket.emitOnConnection('subscribe-credit-annotation', lastUpdateCreditAnnotationDate);
    Websocket.emitOnConnection('subscribe-fund-account', lastUpdateFundAccountDate);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().statement.statements;
    saveOnLocalStorage(STATEMENT_KEY, lst);
    saveCustomLastUpdatedDate(STATEMENT_LAST_UPDATED_DATE_KEY, lst, x => x.createdAt);

    const lstBalance = getState().statement.lstBalance;
    saveOnLocalStorage(BALANCE_KEY, lstBalance);
    saveCustomLastUpdatedDate(BALANCE_LAST_UPDATED_DATE_KEY, lstBalance, x => x.updatedAt);

    const lstCreditAnnotation = getState().statement.lstCreditAnnotation;
    saveOnLocalStorage(CREDIT_ANNOTATIONS_KEY, lstCreditAnnotation);
    saveCustomLastUpdatedDate(CREDIT_ANNOTATIONS_LAST_UPDATED_DATE_KEY, lstCreditAnnotation, x => x.createdAt);

    const lstFundAccount = getState().statement.lstFundAccount;
    saveOnLocalStorage(FUND_ACCOUNT_KEY, lstFundAccount);
    saveCustomLastUpdatedDate(FUND_ACCOUNT_LAST_UPDATED_DATE_KEY, lstFundAccount, x => x.createdAt);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lstStatement = retrieveFromLocalStorage<StatementTransaction[]>(STATEMENT_KEY);
    const lstBalance = retrieveFromLocalStorage<Balance[]>(BALANCE_KEY);
    const lstCreditAnnotation = retrieveFromLocalStorage<CreditAnnotation[]>(CREDIT_ANNOTATIONS_KEY);
    const lstFundAccount = retrieveFromLocalStorage<RequestFundAccount[]>(FUND_ACCOUNT_KEY);

    dispatch(updateStatementList(lstStatement || []));
    dispatch(updateBalanceList(lstBalance || []));
    dispatch(updateCreditAnnotationList(lstCreditAnnotation || []));
    dispatch(updateFundAccountList(lstFundAccount || []));
};

export const getStatement = (client: Client | null) => (state: RootState): StatementTransaction[] => {
    if (!client)
        return [];

    return state.statement.statements.filter(x => x.clientId === client.id);
};
export const getSelectedTransaction = (state: RootState): SelectedTransaction | undefined => {
    const statement = state.statement.statements.find(x => x.id === state.statement.selectedTransactionId);

    if (!statement)
        return undefined;

    const getter = {
        [TransactionTypeEnum.deposit]: (id: string) => state.deposit.deposits.find(x => x.id === id),
        [TransactionTypeEnum.withdraw]: (id: string) => state.withdraw.withdraws.find(x => x.id === id),
        [TransactionTypeEnum.creditAnnotation]: (id: string) => state.statement.lstCreditAnnotation.find(x => x.id === id),
        [TransactionTypeEnum.fundAccount]: (id: string) => state.statement.lstFundAccount.find(x => x.id === id),
    }[statement.type];

    const transaction = getter(statement.id);

    if (!transaction)
        return undefined;

    return {
        type: statement.type,
        transaction,
    };
};
export const getBalance = (clientId: string) => (state: RootState): Balance | undefined => state.statement.lstBalance.find(
    x => x.clientId === clientId);

export const getPartnerBalance = (partnerId?: string) => (state: RootState): Balance => {
    const result = state.statement.lstBalance.find(x => x.partnerId === partnerId && !x.clientId);

    if (!result) {
        return {
            partnerId: partnerId || generateUuid(),
            balances: {
                btc: 0,
                eth: 0,
                usdc: 0,
                usdt: 0,
                trx: 0,
            },
            updatedAt: new Date(0),
        };
    }

    return result;
};

export default statementSlice.reducer;
