import React, { FunctionComponent } from 'react';
import { Client } from '../../../../types/Client';
import { Card } from '../../../utils/card';
import styles from './client-profile.module.scss';
import { Avatar } from '../../../utils/avatar';
import { Label } from '../../../utils/label';
import { Amount, CoinEnum } from '../../../../types/Amount';
import { getCoinInfo } from '../../../../utils/constants';
import { getProfileIsOpen, toggleProfileOpen } from '../../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useSelector } from 'react-redux';
import { formatAmount, labelClient } from '../../../../utils/formatters';
import { getBalance } from '../../../../store/statementSlice';

interface Props {
    client: Client;
}

export const ClientProfile: FunctionComponent<Props> = ({ client }) => {
    const dispatch = useAppDispatch();
    const profileIsOpened = useSelector(getProfileIsOpen);

    const toggleProfileIsOpened = () => dispatch(toggleProfileOpen());

    const renderBalance = (item: Amount) => {
        const coinInfo = getCoinInfo(item.coin);
        return <Label key={item.coin} text={coinInfo.label} content={formatAmount(item)} />;
    };

    const balance = useAppSelector(getBalance(client.id));

    const balances = [CoinEnum.btc, CoinEnum.eth, CoinEnum.trx, CoinEnum.usdc, CoinEnum.usdt]
        .map(coin => {
            return { value: balance?.balances[coin] || 0, coin } as Amount;
        })
        .filter(x => !!x.value);

    return (
        <Card
            className={`${styles.clientProfile} ${profileIsOpened ? styles.profileIsOpened : ''}`}
            classNameBody={styles.contentCard}>
            <div className={styles.clientProfileHeader}>
                <Avatar className={styles.avatar} size="big" />
                <div className={styles.avatarName} onClick={toggleProfileIsOpened}>
                    <h3>{labelClient(client)}</h3>
                </div>
            </div>

            <div className={styles.clientProfileBody}>
                {balances.map(renderBalance)}
            </div>
        </Card>
    );
};
