import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ClientProfile } from './client-profile';
import styles from './client.module.scss';
import { ClientDetails } from './client-details/clientDetails';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from '../../utils/breadcrumb';
import { getSelectedClient, selectClient, unselectClient } from '../../../store/clientSlice';
import { labelClient } from '../../../utils/formatters';

export const Client = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const client = useSelector(getSelectedClient);

    useEffect(() => {
        dispatch(selectClient(id!));

        return () => {
            dispatch(unselectClient());
        };
    }, [dispatch, id]);

    if (!client)
        return <div>loading...</div>;

    const breadcrumb: BreadcrumbItem[] = [
        { label: 'Clients', link: '/clients' },
        { label: labelClient(client) }
    ];

    return (
        <div>
            <Breadcrumb data={breadcrumb} />
            <div className={styles.clientBody}>
                <ClientProfile client={client} />
                <ClientDetails client={client} />
            </div>
        </div>
    );
};
