import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePartnerDto, HasSecretDto, Partner, UpdatePartnerDto } from '../types/Partner';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Columns, downloadCsv } from '../utils/report';
import { formatFee } from '../utils/formatters';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { delay, upsertOnListCustom } from '../utils';
import { Websocket } from '../services/Websocket';
import { HasId } from '../types';
import UserService from '../services/UserService';
import { showAlert } from './notificationSlice';


export const PARTNERS_KEY = 'partners';

interface PartnerState {
    partners: Partner[];
    isLoading: boolean;
    recentlyCreatedSecret: HasSecretDto | undefined;
    selectedPartnerId: string | null;
    addCreditAnnotationIsOpened: boolean;
}

const initialState: PartnerState = {
    partners: [],
    isLoading: false,
    recentlyCreatedSecret: undefined,
    selectedPartnerId: null,
    addCreditAnnotationIsOpened: false,
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        updatePartnerList: (state, action: PayloadAction<Partner[]>) => {
            state.partners = action.payload;
        },
        upsertPartner: (state, action: PayloadAction<Partner>) => {
            state.partners = upsertOnListCustom(state.partners, action.payload, x => x.id);
        },
        removePartner: (state, action: PayloadAction<string>) => {
            state.partners = state.partners.filter(x => x.id !== action.payload);
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setRecentlyCreatedSecret: (state, action: PayloadAction<HasSecretDto | undefined>) => {
            state.recentlyCreatedSecret = action.payload;
        },
        clearRecentlyCreatedSecret: (state) => {
            state.recentlyCreatedSecret = undefined;
        },
        selectPartner: (state, action: PayloadAction<Partner>) => {
            state.selectedPartnerId = action.payload.id;
        },
        unselectPartner: state => {
            state.selectedPartnerId = null;
        },
        openAddCreditAnnotation: (state, action: PayloadAction<Partner>) => {
            state.selectedPartnerId = action.payload.id;
            state.addCreditAnnotationIsOpened = true;
        },
        closeAddCreditAnnotation: state => {
            state.addCreditAnnotationIsOpened = false;
        },
    }
});

export const {
    updatePartnerList,
    upsertPartner,
    setRecentlyCreatedSecret,
    clearRecentlyCreatedSecret,
    selectPartner,
    unselectPartner,
    openAddCreditAnnotation,
    closeAddCreditAnnotation,
} = partnerSlice.actions;

const {
    setIsLoading,
    removePartner
} = partnerSlice.actions;

export const createPartner = (dto: CreatePartnerDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.createPartner(dto)
        .then((val) => {
            dispatch(setIsLoading(false));
            console.log('recently created secret', val);
            dispatch(setRecentlyCreatedSecret(val));
            showAlert('Partner successfully created');
        })
        .catch(err => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const updatePartner = (dto: UpdatePartnerDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.updatePartner(dto)
        .then((val) => {
            dispatch(setIsLoading(false));
            showAlert('Partner updated');
        })
        .catch(err => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const deletePartner = (id: string): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.deletePartner(id)
        .then(() => {
            showAlert('Partner deleted');
            dispatch(setIsLoading(false));
        })
        .catch((err) => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const generateSecret = (id: string): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.createSecret(id)
        .then((val) => {
            dispatch(setIsLoading(false));
            dispatch(setRecentlyCreatedSecret(val));
            showAlert('Secret generated');
        })
        .catch(err => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const downloadPartnersList = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().partner.partners;

    const columns: Columns<Partner> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Name', getValue: x => x.name },
        { title: 'Webhook Url', getValue: x => x.webhookUrl },
        { title: 'Bitcoin Fee', getValue: x => formatFee(x.withdrawFees.bitcoin) },
        { title: 'Usdc Fee', getValue: x => formatFee(x.withdrawFees.usdCoin) },
        { title: 'Ether Fee', getValue: x => formatFee(x.withdrawFees.ether) },
        { title: 'Trx Fee', getValue: x => formatFee(x.withdrawFees.trx) },
        { title: 'Tether Ethereum Fee', getValue: x => formatFee(x.withdrawFees.tether.ethereum) },
        { title: 'Tether Tron Fee', getValue: x => formatFee(x.withdrawFees.tether.tron) },
    ];

    downloadCsv(lst, columns, 'partners.csv');
};

export const cleanPartnerStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(updatePartnerList([]));
    removeOnLocalStorage(PARTNERS_KEY);
};

export const setupPartnerState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Partner>('update-partner', (partner) => {
        dispatch(upsertPartner(partner));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<Partner[]>('update-all-partners', (partners) => {
        dispatch(updatePartnerList(partners));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<HasId>('delete-partner', (obj) => {
        dispatch(removePartner(obj.id));
        return updateOnStorage(getState);
    });
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().partner.partners;
    saveOnLocalStorage(PARTNERS_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Partner[]>(PARTNERS_KEY);

    if (!lst) {
        return;
    }

    dispatch(updatePartnerList(lst));
};

export const getSelectedPartner = (root: RootState): Partner | null =>
    root.partner.partners.find(x => x.id === root.partner.selectedPartnerId) || null;
export const getAddCreditAnnotationIsOpened = (state: RootState): boolean => state.partner.addCreditAnnotationIsOpened;

export const getRecentlyCreatedSecret = (state: RootState): HasSecretDto | undefined => state.partner.recentlyCreatedSecret;
export const getPartnerIsLoading = (state: RootState): boolean => state.partner.isLoading;
export const getPartners = (state: RootState): Partner[] => state.partner.partners;
export const getPartner = (id?: string) => (state: RootState): Partner | undefined => {
    if (!id)
        return;

    return state.partner.partners.find(x => x.id === id);
};

export default partnerSlice.reducer;
