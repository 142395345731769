import React, { FunctionComponent, useEffect } from 'react';
import {
    clearEthereumBalances,
    fetchEthereumBalances,
    getEthereumAddressesWithClients,
    openEthereumTransferPopUp,
} from '../../store/ethereumWalletsSlice';
import { WithData } from '../../types';
import { EthereumAddressWithClient } from '../../types/EthereumAddresses';
import { Card } from '../utils/card';
import { CopyButton } from '../utils/copy-button';
import { HeaderApp } from '../utils/header';
import { IconButton } from '../utils/icon-button';
import styles from './ethereum-wallets.module.scss';
import { SuccessTransferEthereum } from './success-transfer-ethereum';
import { TransferEthereum } from './transfer-ethereum';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { CoinEnum, NetworkEnum } from '../../types/Amount';
import { formatAmount, labelClient } from '../../utils/formatters';
import { setIsAggregationOpened } from '../../store/appSlice';
import { AggregateWalletsBalance } from '../aggregate-wallets-balance';

const EthereumWalletItem: FunctionComponent<WithData<EthereumAddressWithClient>> = ({ data }) => {
    const dispatch = useAppDispatch();

    const { clientId, address, balance } = data;

    const ethBalance = balance.find(x => x.coin === CoinEnum.eth)!;
    const usdcBalance = balance.find(x => x.coin === CoinEnum.usdc)!;
    const usdtBalance = balance.find(x => x.coin === CoinEnum.usdt)!;

    return (
        <tr className={!!clientId ? '' : styles.main}>
            <td data-title="Client">{labelClient(data.client)}</td>
            <td data-title="Address">
                <div className={styles.address}>
                    <span>{address}</span>
                    <CopyButton label="Ethereum address" value={address} />
                </div>
            </td>
            <td data-title="ETH">
                {formatAmount(ethBalance)}
            </td>
            <td data-title="USDC">
                {formatAmount(usdcBalance)}
            </td>
            <td data-title="USDT">
                {formatAmount(usdtBalance)}
            </td>
            <td>
                <IconButton icon="share" onClick={() => dispatch(openEthereumTransferPopUp(data))} />
            </td>
        </tr>
    );
};

export const EthereumWallets = () => {
        const lst = useSelector(getEthereumAddressesWithClients);

        const dispatch = useAppDispatch();
        useEffect(() => {
            dispatch(fetchEthereumBalances());

            return () => {
                clearEthereumBalances();
            };
        }, []);

        if (!lst)
            return (<div>loading...</div>);

        return (
            <>
                <Card className={styles.ethereumWallets}>
                    <HeaderApp title="Ethereum Wallets">
                        <IconButton icon="merge" onClick={() => dispatch(setIsAggregationOpened(true))} />
                    </HeaderApp>
                    <div className={styles.tableWallets}>
                        <table>
                            <thead>
                            <tr>
                                <th>Client</th>
                                <th>Address</th>
                                <th>ETH</th>
                                <th>USDC</th>
                                <th>USDT</th>
                            </tr>
                            </thead>
                            <tbody>
                            {lst.map((x, key) => (
                                <EthereumWalletItem data={x} key={key} />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
                <TransferEthereum />
                <SuccessTransferEthereum />
                <AggregateWalletsBalance network={NetworkEnum.ethereum} />
            </>
        );
    }
;
