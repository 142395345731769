import React, { ReactElement } from 'react';
import styles from './app.module.scss';
import { RoleEnum, User } from './types/User';
import { useAppSelector } from './hooks';
import { getLoggedUser } from './store/authSlice';
import { Login } from './components/app/login';
import { Alerts } from './components/app/alerts';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { getRoutes, getRoutesWithMenu } from './utils/routes';
import { ChangePassword } from './components/change-password';
import { SnackBar } from './components/app/snack-bar';
import { RenewTokenHelper } from './components/app/renew-token-helper';
import { Confirmation } from './components/app/confirmation';
import { About } from './components/app/about';
import { AppBar } from './components/app/app-bar';
import { Menu } from './components/app/menu';
import { AddCreditAnnotation } from './components/partners/add-credit-annotation';

export interface IRoute {
    path: string;
    Component: () => ReactElement;
    authorized: RoleEnum[];
}

export interface MenuItem extends IRoute {
    icon: any;
    label: string;
}

const renderRoutes = (loggedUser: User): ReactElement => {
    const routesWithMenu = getRoutesWithMenu(loggedUser);
    const routes = getRoutes(loggedUser);

    return (
        <Route
            element={
                <div className={styles.app}>
                    <Menu />
                    <div className={styles.content}>
                        <AppBar />
                        <Outlet />
                    </div>
                </div>
            }>
            {routes.map(route => (
                <Route path={route.path} key={route.path} element={<route.Component />} />
            ))}
            <Route path="*" element={<Navigate to={routesWithMenu[0].path} />} />
        </Route>
    );
};

const App = () => {
    const loggedUser = useAppSelector(getLoggedUser);

    if (!loggedUser) {
        return (
            <>
                <Alerts />
                <Login />
            </>
        );
    }

    return (
        <>
            <SnackBar />
            <Alerts />
            <RenewTokenHelper />
            <Confirmation />
            <BrowserRouter>
                <About />
                <Routes>
                    {renderRoutes(loggedUser)}
                </Routes>
            </BrowserRouter>
            <ChangePassword />
            <AddCreditAnnotation />
        </>
    );
};

export default App;
