import React from 'react';
import { closeConfirmation, getConfirmationIsOpen } from '../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Confirm } from '../../utils/confirm';

export const Confirmation = () => {
    const dispatch = useAppDispatch();
    const confirmation = useAppSelector(getConfirmationIsOpen);

    if (!confirmation)
        return null;

    const onClose = () => dispatch(closeConfirmation());

    const onConfirmed = () => {
        confirmation.onConfirm();
        onClose();
    };
    return (
        <Confirm
            isOpen={!!confirmation}
            title={confirmation.title}
            subtitle={confirmation.subtitle}
            text={confirmation.text}
            onConfirmed={onConfirmed}
            onClose={onClose}
        />
    );
};
