import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { EthereumStandaloneTransferRequest, EthereumTransferResponse } from '../types/EthereumTransfer';
import { TronAddress, TronBalance } from '../types/TronAddress';
import { CoinEnum } from '../types/Amount';

const baseUrl = process.env.REACT_APP_TRON_GATEWAY_API;
const generateUrl = getUrlGenerator(baseUrl);

enum OldCoinEnum {
    usdt = 2,
    btc = 3,
    usdc = 4,
    eth = 5,
    trx = 8,
}

const oltToNewCoin: Record<OldCoinEnum, CoinEnum> = {
    [OldCoinEnum.usdt]: CoinEnum.usdt,
    [OldCoinEnum.btc]: CoinEnum.btc,
    [OldCoinEnum.usdc]: CoinEnum.usdc,
    [OldCoinEnum.eth]: CoinEnum.eth,
    [OldCoinEnum.trx]: CoinEnum.trx,
};

const newToOldCoin: Record<CoinEnum, OldCoinEnum> = {
    [CoinEnum.usdt]: OldCoinEnum.usdt,
    [CoinEnum.btc]: OldCoinEnum.btc,
    [CoinEnum.usdc]: OldCoinEnum.usdc,
    [CoinEnum.eth]: OldCoinEnum.eth,
    [CoinEnum.trx]: OldCoinEnum.trx,
};

interface OldAmount {
    coin: OldCoinEnum;
    value: number;
}

export interface OldNetworkBalance {
    clientId?: string;
    address: string;
    balance: OldAmount[];
}

export default class TronGatewayService {
    public static listWallets = async (): Promise<TronAddress[]> =>
        Api.get(generateUrl('wallet', 'list'));

    public static transfer = (req: EthereumStandaloneTransferRequest): Promise<EthereumTransferResponse> =>
        Api.post(generateUrl('transfer'), req);

    public static getBalance = (key: string): Promise<TronBalance> =>
        Api.get<OldNetworkBalance>(generateUrl('wallet', 'balance', key))
            .then(x => ({
                clientId: x.clientId,
                address: x.address,
                balance: x.balance.map(z =>
                    ({ coin: oltToNewCoin[z.coin] as CoinEnum, value: z.value })),
            }));

    public static aggregate = (minimum: number, coin: CoinEnum, address: string): Promise<void> =>
        Api.post(generateUrl('transfer', 'aggregate'), {
            minimumAmount: minimum,
            coin: newToOldCoin[coin],
            address
        });


}
