export enum CoinEnum {
    btc = 'btc',
    eth = 'eth',
    usdt = 'usdt',
    trx = 'trx',
    usdc = 'usdc',
}

export enum NetworkEnum {
    bitcoin = 'bitcoin',
    ethereum = 'ethereum',
    tron = 'tron'
}

export interface Amount {
    coin: CoinEnum;
    value: number;
}
