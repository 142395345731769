import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../types/Client';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { delay, upsertOnList } from '../utils';
import { BasePagination } from '../types';
import { getPaged } from '../utils/pagination';
import { Columns, downloadCsv } from '../utils/report';
import { documentFormatter, formatCryptoAddress } from '../utils/formatters';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    retrieveLastUpdatedDateOrDefault,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { Websocket } from '../services/Websocket';

const CLIENTS_KEY = 'clients';
const CLIENTS_LAST_UPDATED_DATE_KEY = 'clients_last_updated';

interface ClientState {
    lstClients: Client[];
    selectedClientId: string | null;
}

const initialState: ClientState = {
    lstClients: [],
    selectedClientId: null,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        updateClientList: (state, action: PayloadAction<Client[]>) => {
            state.lstClients = action.payload;
        },
        upsertClient: (state, action: PayloadAction<Client>) => {
            state.lstClients = upsertOnList(state.lstClients, action.payload);
        },
        selectClient: (state, action: PayloadAction<string>) => {
            state.selectedClientId = action.payload;
        },
        unselectClient: state => {
            state.selectedClientId = null;
        },
    },
});

export const {
    updateClientList,
    upsertClient,
    selectClient,
    unselectClient,
} = clientSlice.actions;

export const downloadClients = (): AppThunk => async (dispatch, getState) => {
    const lst = getState().client.lstClients || [];

    const biggestNumberOfWallets = lst.reduce((acc, x) => Math.max(acc, x.wallets.length), 0);

    const getWalletsColumns = (index: number): Columns<Client> => [
        { title: `Endereço ${index + 1}`, getValue: x => formatCryptoAddress(x.wallets[index]) }
    ];

    const walletsColumns = Array.from(
        { length: biggestNumberOfWallets },
        (_, i) => getWalletsColumns(i)
    ).flat();

    const columns: Columns<Client> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Partner Id', getValue: x => x.partnerId },
        { title: 'Document', getValue: x => documentFormatter(x.document) },
        { title: 'Name', getValue: x => x.name || '-' },
        { title: 'Birth Date', getValue: x => x.birthDate?.toISOString().slice(10) || '-' },
        { title: 'Created At', getValue: x => x.createdAt.toLocaleString() },
        ...walletsColumns
    ];

    downloadCsv(lst, columns, 'clients.csv');
};

export const cleanClientStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(updateClientList([]));
    removeOnLocalStorage(CLIENTS_KEY);
    removeOnLocalStorage(CLIENTS_LAST_UPDATED_DATE_KEY);
};

export const setupClientState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Client>('update-client', client => {
        dispatch(upsertClient(client));
        return updateOnStorage(getState);
    });

    const payload = retrieveLastUpdatedDateOrDefault(CLIENTS_LAST_UPDATED_DATE_KEY);
    Websocket.emitOnConnection('subscribe-client', payload);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().client.lstClients;
    saveOnLocalStorage(CLIENTS_KEY, lst);
    saveLastUpdatedDate(CLIENTS_LAST_UPDATED_DATE_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Client[]>(CLIENTS_KEY);

    if (!lst)
        return;

    dispatch(updateClientList(lst));
};

export const getSelectedClient = (root: RootState): Client | null => {
    const selectedClient = root.client.lstClients.find(x => x.id === root.client.selectedClientId);
    return selectedClient || null;
};
export const getLstClients = (pagination: BasePagination) => (root: RootState) => {
    const lst = root.client.lstClients;
    return getPaged(pagination, lst);
};
export const getTotalClients = (root: RootState): number => root.client.lstClients.length;

export default clientSlice.reducer;
