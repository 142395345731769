import { User } from '../types/User';
import { HasId } from '../types';
import { isSameDay } from 'date-fns';
import Decimal from 'decimal.js';

export const isValidDateString = (value: any): boolean =>
    /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(value);

export function apiResponseFormatter<T>(obj: T): T {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
        return obj.map(i => apiResponseFormatter(i)) as any;
    }

    if (typeof obj === 'string' && isValidDateString(obj)) {
        return new Date(obj) as any;
    }

    if (typeof obj === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = apiResponseFormatter(obj[key]);
            return acc;
        }, {}) as T;
    }

    return obj;
}

export const oneDay = 1000 * 60 * 60 * 24;
export const isToday = (dt: Date): boolean => isSameDay(dt, new Date());
export const isYesterday = (dt: Date): boolean => isSameDay(dt, new Date(Date.now() - oneDay));

export const generateUuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const add = (first: number, lastNumber: number): number => {
    const result = new Decimal(first).add(lastNumber);
    return Number(result);
};

export const delay = (time: number): Promise<void> =>
    new Promise<void>(resolve => setTimeout(resolve, time));


export const userFromToken = (token: string): User | undefined => {
    if (!token)
        return;

    const payloadToken = token.split('.')[1];

    if (!payloadToken)
        return;

    const decoded = window.atob(payloadToken);
    return JSON.parse(decoded);
};

export const extractNumber = (val: string | undefined): string =>
    val?.match(/\d/g)?.join('') || '';

export const getUrlGenerator = (baseUrl: string | undefined) => (...args: (string | number | object)[]) => {
    const params = args
        .map(x => {
            if (typeof x === 'object') {
                const urlParams = new URLSearchParams(x as Record<any, any>);

                const query = Array.from(urlParams.keys())
                    .filter(x => urlParams.get(x) !== 'undefined')
                    .map(x => `${x}=${urlParams.get(x)}`)
                    .join('&');

                return `?${query}`;
            }

            return x;
        })
        .join('/');

    return `${baseUrl}/${params}`;
};

export const upsertOnList = <T extends HasId>(lst: T[] | null, obj: T): T[] => upsertOnListCustom(lst, obj, x => x.id);

export const upsertOnListCustom = <T, U>(lst: T[] | null, obj: T, getId: (obj: T) => U): T[] => {
    if (!lst)
        return [obj];

    const position = lst.map(x => getId(x)).indexOf(getId(obj));

    if (position === -1)
        return [...lst, obj];

    const newList = lst.filter(x => getId(x) !== getId(obj));
    newList.splice(position, 0, obj);

    return newList;
};


export const userIsEqual = (newObj: Partial<User> | null, oldObj: Partial<User> | null): boolean => {
    if (oldObj === null || newObj === null)
        return false;

    return newObj.name === oldObj.name &&
        newObj.phoneNumber === oldObj.phoneNumber &&
        newObj.email === oldObj.email;
};
