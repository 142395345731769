import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Websocket } from '../services/Websocket';
import { HasId } from '../types';
import { Columns, downloadCsv } from '../utils/report';
import { phoneFormatter } from '../utils/formatters';
import { showAlert } from './notificationSlice';
import { CreateUserDto, RoleEnum, UpdateMySelfDto, UpdateUserDto, User } from '../types/User';
import UserService from '../services/UserService';

const BACKOFFICE_USERS_KEY = 'backoffice-users';

interface OperatorState {
    lstBackofficeUser: User[];
    isLoading: boolean;
}

const initialState: OperatorState = {
    lstBackofficeUser: [],
    isLoading: false,
};

const operatorSlice = createSlice({
    name: 'operator',
    initialState,
    reducers: {
        upsertOperator: (state, action: PayloadAction<User>) => {
            state.lstBackofficeUser = upsertOnList(state.lstBackofficeUser, action.payload);
        },
        removeOperator: (state, action: PayloadAction<string>) => {
            state.lstBackofficeUser = state.lstBackofficeUser.filter(x => x.id !== action.payload);
        },
        updateOperatorList: (state, action: PayloadAction<User[]>) => {
            state.lstBackofficeUser = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    }
});

const {
    upsertOperator,
    removeOperator,
    updateOperatorList,
    setIsLoading,
} = operatorSlice.actions;

export const createOperator = (obj: CreateUserDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    return UserService.createOperator(obj)
        .then((x) => {
            showAlert('Operator created successfully');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const updateOperator = (obj: UpdateUserDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    return UserService.updateOperator(obj)
        .then(() => {
            showAlert('Operator updated successfully');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const deleteOperator = (id: string): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    return UserService.deleteOperator(id)
        .then(() => {
            showAlert('Operator deleted successfully');
        })
        .finally(() => dispatch(setIsLoading(false)));
};

export const resetOperatorPassword = (id: string): AppThunk => dispatch =>
    UserService.resetUserPassword(id)
        .then(() => {
            dispatch(showAlert('Password was reset'));
        });

export const updateMySelfInfo = (obj: UpdateMySelfDto): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoading(true));

    UserService.updateMySelf(obj)
        .then(() => {
            dispatch(showAlert('You user was updated successfully'));
            dispatch(setIsLoading(false));
        })
        .catch((err) => {
            dispatch(setIsLoading(true));
            throw err;
        });
};

export const downloadOperatorsList = (): AppThunk => async (dispatch, getState) => {
    const columns: Columns<User> = [
        { title: 'Id', getValue: x => x.id },
        { title: 'Nome', getValue: x => x.name },
        { title: 'Telefone', getValue: x => phoneFormatter(x.phoneNumber) },
        { title: 'Email', getValue: x => x.email },
    ];

    const lst = getState().operator.lstBackofficeUser
        .filter(x => x.role === RoleEnum.operator);
    downloadCsv(lst, columns, 'operators.csv');
};

export const cleanOperatorStateAndStorage = (): AppThunk => dispatch => {
    dispatch(updateOperatorList([]));
    removeOnLocalStorage(BACKOFFICE_USERS_KEY);
};

export const setupOperatorState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<User>('update-backoffice-user', operator => {
        dispatch(upsertOperator(operator));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<HasId>('delete-backoffice-user', obj => {
        dispatch(removeOperator(obj.id));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<User[]>('update-all-backoffice-users', lst => {
        dispatch(updateOperatorList(lst));
        return updateOnStorage(getState);
    });
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const lst = getState().operator.lstBackofficeUser;
    saveOnLocalStorage(BACKOFFICE_USERS_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<User[]>(BACKOFFICE_USERS_KEY);
    if (!lst)
        return;

    dispatch(updateOperatorList(lst));
};

export const getMySelf = (state: RootState): User | null => {
    const user = state.auth.loggedUser;
    return state.operator.lstBackofficeUser.find(x => x.id === user?.id) || null;
};

export const getOperatorIsLoading = (state: RootState): boolean => state.operator.isLoading;
export const getOperator = (id: string | undefined) => (state: RootState): User | null =>
    state.operator.lstBackofficeUser.find(x => x.id === id) || null;

export const getOperators = (state: RootState): User[] =>
    state.operator.lstBackofficeUser.filter(x => x.role === RoleEnum.operator);

export const getOperatorsTotal = (state: RootState): number =>
    state.operator.lstBackofficeUser
        .filter(x => x.role === RoleEnum.operator)
        .length;

export default operatorSlice.reducer;
