import { LoginRequest, User } from '../types/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import UserService from '../services/UserService';
import { Api } from '../services/Api';
import { ChangePasswordDto } from '../types/ChangePasswordDto';
import { Websocket } from '../services/Websocket';
import { showAlert } from './notificationSlice';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { delay, userFromToken } from '../utils';

export const TOKEN_KEY = 'token';
export const PASSWORD_MUST_BE_CHANGED_KEY = 'password-must-be-changed';


enum LoginStateEnum {
    willDoBasicAuth = 'not-started',
    willDoTwoFactorAuth = 'started'
}

interface AuthState {
    loggedUser: User | null,
    loginState: LoginStateEnum;
    tokenExpiresAt: number | null;
    passwordMustBeChanged: boolean;
    changePasswordDialogIsOpened: boolean;
    error: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    loggedUser: null,
    tokenExpiresAt: null,
    loginState: LoginStateEnum.willDoBasicAuth,
    passwordMustBeChanged: false,
    changePasswordDialogIsOpened: false,
    error: '',
    isLoading: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoggedUser: (state, action: PayloadAction<User | null>) => {
            state.loggedUser = action.payload;
        },
        updateStateLogin: (state, action: PayloadAction<LoginStateEnum>) => {
            state.loginState = action.payload;
        },
        updateLoginError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
        setPasswordMustBeChanged: (state, action: PayloadAction<boolean>) => {
            state.passwordMustBeChanged = action.payload;
        },
        openChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = true;
        },
        closeChangePasswordDialog: state => {
            state.changePasswordDialogIsOpened = false;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

const { updateLoggedUser, updateStateLogin, updateTokenExpiration, setPasswordMustBeChanged } = authSlice.actions;
export const {
    updateLoginError,
    openChangePasswordDialog,
    closeChangePasswordDialog,
    setIsLoading
} = authSlice.actions;

export const renewToken = (): AppThunk => dispatch =>
    UserService.renewToken();

export const create2FA = (email: string, password: string): AppThunk => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.create2FA(email, password)
        .then(() => {
            dispatch(setIsLoading(false));
            return dispatch(updateStateLogin(LoginStateEnum.willDoTwoFactorAuth));
        })
        .catch(error => {
            dispatch(setIsLoading(false));
            return dispatch(updateLoginError(error.message));
        });
};

export const login = (obj: LoginRequest): AppThunk => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.login(obj)
        .then(({ token, passwordMustBeChanged }) => {
            dispatch(updateToken(token, passwordMustBeChanged));
            dispatch(updateStateLogin(LoginStateEnum.willDoBasicAuth));
            dispatch(setIsLoading(false));
        })
        .catch(({ message }) => {
            dispatch(setIsLoading(false));
            return dispatch(updateLoginError(message));
        });
};

export const changePassword = (changePasswordForm: ChangePasswordDto): AppThunk => async dispatch => {
    dispatch(setIsLoading(true));

    await UserService.changePassword(changePasswordForm)
        .then(() => {
            dispatch(closeChangePasswordDialog());
            dispatch(showAlert('Password changed!'));
            dispatch(setIsLoading(false));
        })
        .catch(err => {
            dispatch(showAlert('Error changing password'));
            dispatch(setIsLoading(false));
            throw err;
        });
};

let timeout: NodeJS.Timeout;
export const updateToken = (token: string, passwordMustBeChanged: boolean): AppThunk => async dispatch => {
    saveOnLocalStorage(TOKEN_KEY, token);
    saveOnLocalStorage(PASSWORD_MUST_BE_CHANGED_KEY, String(passwordMustBeChanged));

    Api.setToken(token);
    Websocket.updateToken(token);

    const user = userFromToken(token)!;

    const expiration = (user as any).exp;
    if (expiration <= Date.now()) {
        dispatch(logoff());
        return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(logoff()), expiration - Date.now());

    dispatch(updateTokenExpiration((user as any).exp));
    dispatch(updateLoggedUser(user));
    dispatch(setPasswordMustBeChanged(passwordMustBeChanged));

    if (passwordMustBeChanged)
        dispatch(openChangePasswordDialog());

    await Websocket.openWebsocketConnection();
};

export const updateOfflineToken = (token: string, passwordMustBeChanged: boolean): AppThunk => dispatch => {
    const user = userFromToken(token)!;
    dispatch(updateLoggedUser(user));
    dispatch(updateTokenExpiration(null));
};

export const loginFromStorage = (): AppThunk => async (dispatch, getState) => {
    const token = retrieveFromLocalStorage<string>(TOKEN_KEY);
    const passwordMustBeChanged = retrieveFromLocalStorage<string>(PASSWORD_MUST_BE_CHANGED_KEY) === 'true';

    if (!token)
        return;

    let hasConnection = await Api.hasConnectionToServer();
    const waitForConnection = async (): Promise<void> => {
        if (hasConnection) {
            return;
        }

        await delay(10 * 1000);
        hasConnection = await Api.hasConnectionToServer();
        return waitForConnection();
    };

    if (!hasConnection)
        dispatch(updateOfflineToken(token, passwordMustBeChanged));

    waitForConnection()
        .then(() => dispatch(updateToken(token, passwordMustBeChanged)));
};

export const cleanAuthStorageAndLogoff = (): AppThunk => dispatch => {
    removeOnLocalStorage(TOKEN_KEY);
    removeOnLocalStorage(PASSWORD_MUST_BE_CHANGED_KEY);
    dispatch(logoff());
};

export const logoff = (): AppThunk => dispatch => {
    Api.setToken('');
    Websocket.updateToken('');

    dispatch(updateTokenExpiration(null));
    dispatch(updateLoggedUser(null));
    dispatch(setPasswordMustBeChanged(false));
    dispatch(updateStateLogin(LoginStateEnum.willDoBasicAuth));
};

export const getAuthIsLoading = (state: RootState): boolean => state.auth.isLoading;
export const getLoggedUser = (state: RootState): User | null => state.auth.loggedUser;
export const getLoginIsStarted = (state: RootState): boolean => state.auth.loginState === LoginStateEnum.willDoTwoFactorAuth;
export const getErrorAuth = (state: RootState): string | null => state.auth.error;
export const getTokenExpiresAt = (state: RootState): number | null => state.auth.tokenExpiresAt;
export const getChangePasswordIsOpened = (state: RootState): boolean => state.auth.changePasswordDialogIsOpened;
export const getPasswordMustBeChanged = (state: RootState): boolean => state.auth.passwordMustBeChanged;

export default authSlice.reducer;
